@import 'app/assets/stylesheets/helpers/globals';

.associated-accounts {
  th {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.associated-accounts th .tooltip-inner {
  text-transform: none;
}

.associated-accounts-contact-name {
  font-weight: bold;
}

.associated-accounts-contact-name-selected {
  color: $orange;
}

.associated-accounts [data-bs-toggle="tooltip"] {
  cursor: pointer;
}

.associated-accounts-sparse {
  color: $grey1;
  font-style: italic;
}

.associated-accounts-role {
  color: $grey1;
}

.associated-accounts-role-selected {
  font-style: italic;
}