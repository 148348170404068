@import '../shared/helpers/bootstrap_variables';

.contact-header {

  i.fa.fa-info-circle {
    color: $light-grey;
  }

  /*
   * This creates a 2x2 grid like the following:
   *
   *    1____2____3
   *    2____|____|
   *    3____|____|
   *
   * For smaller breakpoints, it will arrange as:
   *
   *    |                 actions |
   *    | ----------------------- |
   *    | personal | contact-info |
   *
   * to prevent `actions` from overlapping with `personal`. For larger breakpoints,
   * this will arrange as:
   *
   *    |          | actions      |
   *    | personal |--------------|
   *    |          | contact-info |
   *
   * The first row is limited-height so the `actions` content uses limited vertical
   * space, while the second row takes up as much is needed.
   */
  .header-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 35px 1fr;
  }

  .header-content__personal {
    grid-column: 1 / 2; // column 1
    grid-row: 2 / 3; // row 2

    @include media-breakpoint-up(xl) {
      grid-row: 1 / 3; // spans rows 1 and 2
    }

    display: flex;
  }

  .header-content__actions {
    grid-column: 1 / 3; // spans columns 1 and 2
    grid-row: 1 / 2; // row 1

    @include media-breakpoint-up(xl) {
      grid-column: 2 / 3; // column 2
    }
  }

  .header-content__contact-info {
    grid-column: 2 / 3; // column 2
    grid-row: 2 / 3; // row 2
  }

  .personal {
    height: 95px;

    h3 {
      color: $body-color;
      font-weight: normal;
      line-height: 13px;
    }
    .contact-name {
      margin: 0 0 0 0;
      white-space: nowrap;
      line-height: 24px;
      color: $body-color;
    }

    .details {
      margin: 5px 0 0 0;
      .divided {
        border-left: 1px solid $divider;
        margin: 0 0 0 -12px;
        padding: 0 0 0 15px;
      }

      .row div[class^='span'], .row div[class*=' span'] {
        min-height: 22px;
        white-space: nowrap;
      }
    }
  }

  .navbar {
    margin: 0;
  }

  .primary-contact-info {
    clear: right;
    float: right;

    .address {
      float: left;
      margin-left: 50px;
    }

    .info {
      height: 17px;
      float: left;
    }

    .name-and-tech {
      float: left;
    }
  }

  .contact-portrait {
    position: relative;
    margin: 0 20px 0 0;
    width: 100px;
    height: 100px;
    border: 1px solid $grey2;
    border-radius: 2px;
    &:hover {
      .overlay {
        visibility: visible;
      }
    }
    &.placeholder {
      .overlay {
        // Move text down so its centered
        .portrait-control-top {
          padding: 25px 0 0 0;
        }
        // Don't show controls when it's just a placeholder image
        .portrait-control-bottom {
          display: none;
        }
      }
      // Upload button takes up full size
      .fileinput-button {
        height: 100px;
      }
    }
    &.uploading {
      .overlay .portrait-control-top {
        padding: 25px 0 0 0;
      }
      .overlay .portrait-control-left, .overlay .portrait-control-right {
        display: none;
      }
    }
    .fileinput-button {
      position: absolute;
      top: 0;
      height: 75px;
      width: 100px;

      input {
        height: 100%;

        &:disabled {
          pointer-events: none;
        }
      }
    }
    .progress {
      margin: 0;
      background: none;
      box-shadow: none;
      border-radius: 0px;
      height: 24px;
      position: absolute;
      width: 100px;
      bottom: 0;
    }
    .overlay {
      width: 100px;
      height: 100px;
      background: rgba($blue0, 0.75);
      visibility: hidden;
      .portrait-control-top {
        height: 65px;
        padding: 10px 0 0 0;
        text-align: center;
        &:before {
          content: 'Upload\A a Photo';
          color: $white;
          font-size: 20px;
          font-weight: bold;
          line-height: 26px;
          white-space: pre;
        }
      }
      .portrait-control-bottom {
        height: 24px;
        .portrait-control-left {
          display: inline-block;
          height: 100%;
          color: $blue0;
          background-color: rgba($white, 0.8);
          float: left;
          border-top: 1px solid $white;
          line-height: 24px;
          width: 50%;
          text-align: center;
          border-right: 1px solid $white;
        }
        .portrait-control-right {
          margin: 0 0 0 -1px;
          display: inline-block;
          height: 100%;
          color: $red;
          background-color: rgba($white, 0.8);
          border-top: 1px solid $white;
          float: right;
          line-height: 24px;
          width: 50%;
          text-align: center;
        }
      }
    }
  }

  .sub-pages {
    background: $lighter-grey;
    border-bottom: 1px solid $divider;
    height: 51px;
    margin: 35px 0;

    a {
      color: $gray6;

      &:hover {
        color: $gray31;
      }
    }

    li.active {
      background: url('../../images/shared_components/tab_caret_up.png') no-repeat bottom center;

      a {
        color: $teal2;
        font-weight: bold;
      }
    }
  }
}
