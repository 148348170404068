@import '../shared/helpers/bootstrap_variables';

.form {
  h1 {
    margin: 0 0 15px;
  }

  h2 {
    padding: 0px 5px;
    margin: 0 0 20px;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;

    a {
      font-size: 12px;
    }
  }

  h3 {
    border-bottom: 1px dotted $divider;
    padding: 0 0 5px;
    margin: 10px 0 10px
  }

  .alert {
    margin-top: 15px;
  }

  .sub-field-label {
    font-size: 11px;
    line-height: 13px;
  }

  .form-text {
    position: relative;
    top: -4px;
    left: 2px;
  }

  .method-label {
    margin: 6px 10px 0 0;
  }



  input, select, textarea {
    &.error-field {
      border: 1px solid $orange1;
    }
  }

  .error {
    color: $red0;
  }

  .custom_field {
    margin-bottom: 0px;

    option[disabled],
    option:disabled {
      display: none;
    }
  }

  .review {
    font-size: 12px;

    .field-label {
      padding: 2px 0 0;
    }

    .static {
      padding: 2px 5px;
      min-height: 22px;
      background: $lighter-grey;
      @include rounded-corners();
    }

    .field-row {
      margin: 0 0 20px;
    }
  }

  .btn-toolbar {
    .btn + form {
      margin-left: 5px;
      display: inline-block;
    }
  }

  .booleanlist-group {
    margin-top: 5px;
  }

  .explanation {
    margin: 0 0 10px 0;
  }
}

/* These apply to both ad hoc forms and teacher rec forms */

.likert {
  table-layout: fixed;
  border-collapse: collapse;
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;

  tbody tr:nth-child(odd) td {
    /* This color is also set programmatically in custom_form.js */
    background-color: $gray52;
  }

  td {
    padding-left: .5em;
    padding-right: .5em;
    color: $gray53;
    text-align: center;
    border: 2px solid $gray54;
    word-wrap: break-word;

    &.prompt-column {
      width: 25%;
    }
  }

  .label-container {
    text-align: left;
    color: rgb(68, 68, 68);
  }

}

.form-horizontal {
  .read-only {
    .col-form-label {
      font-weight: bold;
    }
  }

  .controls {
    .text {
      margin-top: 5px;
    }
  }

  .input-group span + input {
    width: 179px;
  }

  fieldset {
    div.row {
      display:flex;
      flex-direction:row;

      >label,
      >div.col-form-label {
        display:flex;
        flex: 1 1 0px;
        justify-content:flex-end;
        align-self:flex-start;
        padding:0;
        text-align: right;
      }
      >div.controls {
        flex: 10 10 0px;
      }
    }
  }

  >div.row {
    display:flex;
    flex-direction:row;

    >label,
    >div.col-form-label {
      display:flex;
      flex: 5 1 0px;
      justify-content:flex-end;

    }
    >div.controls ,
    >div.controls {
      flex: 10 10 0px;
    }
  }

  /* BEGIN */
  /* I don't we want any of this... */
  .edit-form-wrapper {
    label.col-form-label {
      display: flex;
      justify-content: flex-end;
    }
  }

  .controls {

    input[type="color"],
    input[type="date"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="file"],
    input[type="image"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="tel"],
    input[type="text"]:not(.chosen-container-multi *),
    input[type="time"],
    input[type="url"],
    input[type="week"] {
      @extend .form-control;
      @extend .form-control-sm;
    }

    select {
      @extend .form-select;
      @extend .form-select-sm;
    }
  }
  /* END */
}


//edit / detail modals
.checklist-item-edit,
.communication-plan-editor.modal {
  .mb-3 {
    display:flex;
    font-size:.8rem;

    label {
      font-weight:800;
      padding-right:1rem;
    }

    .select-contacts label {
      @include make-col(24);
      margin-bottom: 3px;
    }

    .controls {
      @include make-col(16);
      align-self: end;
    }
  }
}

.communication-plan-editor.modal {
  .modal-dialog {
    max-width:600px;
  }
}

select {
  border:1px solid $grey2;
  background-color:$white;
  line-height:28px;
  padding:4px 6px;
  margin-bottom:.5rem;
  font-size:13px;
  border-radius:4px;
}

.card.card-well {
  min-height:20px;
  padding:19px;
  margin-bottom:20px;
  background-color:$grey5;
  border:$grey2;
  border-radius:4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.05);

  h5 {
    font-size:13px;
    font-weight:bold;
    margin-top:.5rem;
  }
}


.basic-setup-form{
  input[type="text"]{
    @extend .form-control;
    @extend .form-control-sm;
    width:200px;
  }

  select {
    @extend .form-select;
    @extend .form-select-sm;
    display: inline-block;
    width:200px;
  }
}

.help-inline {
  display:inline-block;
  padding-left:5px;
}

/* Bootstrap3 back-port for help-block */
.help-block {
  display: block;
  margin-top: 0.25rem;
  color: $gray6;
}

.calendar-details{
  section {
    &.calendar-edit-inputs{
      div > {
        label {display:flex; justify-content:flex-end;}
      }
    }

    &.calendar-checkbox-options {

      select {
        @extend .form-select;
        @extend .form-select-sm;

        display: inline-block;
        width: auto;
      }

      .checkbox {
        min-height: 18px;
        padding-left: 20px;

        label {
          line-height: 18px;
        }

        input[type="checkbox"] {
          margin-left: -20px;
        }
      }
    }
  }

}

.mods-and-features{
  .controls {
    select,
    input[type="text"]:not(.chosen-container-multi *) {
      width:220px;
    }

    input[type="checkbox"]{
      margin: calc(0.375rem + 1px) 0;
    }
  }

}

.border-header {
  padding-bottom:.5rem !important;
  margin-bottom:.5rem !important;
  margin-top:1.5rem !important;
  border-bottom:1px solid $gray39 !important;
}

.calendar-in-input {
  position:relative;
}

.canonical-name {
  max-width: 280px;
  padding-right: 0;
}
.canonical-suffix {
  max-width: 100px;
}

// React control component inside haml model

.react-controls {
  padding-left: 35px;
}
