.filter {
  margin: 15px 0;
  display: inline-block;
}

// filter text, tags, and clear button
.filters {
  width: 100%;
  display: inline-block;

  margin-bottom: 15px;

  h5 {
    margin: 8px 0;
  }
}

.filter-text {
  font-weight: 600;
  font-size: 12px;
  margin-right: 5px;
  color: $gray6;
}

.filter .badge {
  font-weight: 600;
}

.filter-clear {
  margin-left: 10px;
}

.filter-term-key {
  font-weight: bold;
}

.filter-terms {
  display: inline;
  font-size: 11px;
  line-height: 24px;
}

.filter-toggle, .toggle-filters {
  margin-right: 12px;
  font-weight: bold;

  i {
    margin: 0 0 0 5px;
  }
}

.filter-toggle i {
  margin-left: 5px;
}

.filter .info-text-xs {
  font-size: 11.9px;
  padding: 0 5px;

  filter: progid:DXImageTransform.Microsoft.Gradient(enabled='false');
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  border: 0;
  box-shadow: none;
  background-image: none;
  background: none;
  font-weight: bold;
  vertical-align: middle;
}

.filter-control {
  margin-bottom: .75rem;
  padding-bottom: .75rem;
  border-bottom: 1px solid $gray3;
}

.filter-search {
  @include make-row();
}

.filter-search-value {
  @include make-col-ready();
  @include make-col(5);
}

.filter-search-input {
  @extend .form-control;
  @extend .form-control-sm;
}
