.audit-history-viewer {

  .modal-dialog {
    max-width:1200px;
  }
}

.audit-history-viewer-body {
  table {
    width: 100%;
  }
  tr {
    border-bottom: 1px solid $grey2;
  }

  td {
    white-space: pre-wrap;
  }

  .audit-history-outer-table {
    tr.audit-history-header {
      background-color: $blue4;
    }

    th, td {
      text-align: center;
    }
    th:nth-child(1),
    th:nth-child(2) {
      width: 15%
    }
    th:nth-child(3){
      width: 70%;
    }
  }

  .audit-history-change-table {
    .audit-history-change-header {
      background-color: $grey5;
    }
    tr {
      border-left: 1px solid $grey2;
    }
    td {
      padding: 5px;
    }
    th:nth-child(1) {
      width: 5%
    }
    th:nth-child(2),
    th:nth-child(3) {
      width: 55%
    }

    tr:last-child {
      border-bottom: 0;
    }
  }

  .audit-history-change-item-before {
    background-color: $pink0;
  }

  .audit-history-change-item-after {
    background-color: $green15;
  }
}
