@import '../shared/helpers/bootstrap_variables';

.contextual-nav {
  @extend .container-fluid;

  list-style: none;
  margin: 0;
  display: flex;
  border-bottom: 1px solid $gray3;

  > li {
    margin: 10px 10px 0 10px;
    padding-bottom: 6px;

    &:first-child {
      margin-left: 0;
    }

    > a {
      color: $gray8;
    }
  }

  > li.active {
    padding-bottom: 3px;
    border-bottom: 3px solid $gray3;

    > a {
      font-weight: bold;
      color: $primary;
    }
  }
}

.contextual-nav-divider {
  width: 1px;
  border-left: 1px solid $gray3;
  height: 18px;
}
