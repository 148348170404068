@import '../../shared/helpers/bootstrap_variables';

.field-customization-list {
  @include make-row();
}

.field-customization-field {
  @include make-col-ready();
  @include make-col(8);

  margin-bottom: 10px;
}

.field-customization-field-input {
  @extend .form-control;
  width: 75%;
}
