.contact-nav {
  display: inline-block;
  font-size: 16px;
  height: 50px;
  margin: 0 auto;
  list-style: none;

  li {
    float: left;
    padding: 17px 20px;
    line-height:17px;

    a {
      display: inline;
      background-color: transparent;
    }
  }
}

.contact-nav-shortcuts {
  float: right;

  li {
    float: right;
  }
}