.triangle-breadcrumb {
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    float: left;

    a {
      display: block;
      padding: 7px 10px 7px 25px;
      float: left;
      position: relative;
      background: $grey5;
      border: 1px solid $grey3;
    }

    a:active,
    a:focus,
    a:hover {
      text-decoration: none;
    }
  }

  > li:not(:last-of-type) a:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 16px solid transparent;           /* Go big on the size, and let overflow hide */
    border-bottom: 16px solid transparent;
    border-left: 15px solid $grey5;
    position: absolute;
    top: 50%;
    margin-top: -16px;
    left: 100%;
    z-index: 2;
  }

  > li:not(:last-of-type) a:before {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 15px solid $grey3;
    position: absolute;
    top: 50%;
    margin-top: -16px;
    margin-left: 1px;
    left: 100%;
    z-index: 1;
  }

  > li:first-of-type a {
    padding-left: 15px;
  }

  > li:last-of-type a {
    padding-right: 15px;
  }

  > li.triangle-breadcrumb-selected {
    a {
      background: $blue1;
      color: $white;
      font-weight: bold;
    }

    a:after {
      border-left-color: $blue1;
    }
  }
}
