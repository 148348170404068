.show-email-modal {
  width: 670px;
  margin-left: -335px;
}

.show-email-label{
  font-weight: bold;
  text-align: right;
}

.email-modal-body-label {
  margin: 20px 0 5px 0;
  font-weight: bold;
}

.email-modal-body {
  font-family: sans-serif;
  min-height: 300px;
  border: 1px dashed $gray3;
}

.failed-email-bulk-action {
  float: right;
}
