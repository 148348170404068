@import 'app/assets/stylesheets/helpers/globals';

#lottery-modal {
  input[name="lottery_tiers[][weight]"] {
    width: 45px;
  }

  .lottery-type {
    input {
      position: relative;
      top: -2px;
      margin: 0 4px;
    }

    border-bottom: 1px dotted $divider;
  }

  .lottery-tier:last-of-type {
    margin-bottom: 5px;
  }

  .add-item-button {
    font-style: italic;
    font-size: 13px;
  }
}