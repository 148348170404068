.modal {

  overflow-y:auto !important;
  padding-bottom: 50px;

  h1 {
    font-size: 22.75px;
    line-height: 24px;
  }

  h2 {
    font-size: 1.4em;
    line-height: 1.1em;
    color: $orange;
    margin: 0 0 .5em;
    font-weight: normal;
  }

  h3 {
    font-size: 13px;
    line-height: 15px;
  }
}

.modal-body {
  z-index: auto !important; //removing this breaks fullscreen froala editor within a modal for Safari only

  .tokens-selector {
    .chosen-drop {
      z-index: 1052;
    }
  }
  .tokens-text {
    .helper-text {
      margin: 0;
    }
  }
  .tokens-value-container {
    div[draggable="true"]{-webkit-user-select:text} //this makes the input editable for safari
    cursor: pointer;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    background-color: white;
    background-color: -moz-field;
    border: 1px solid darkgray;
    border-radius: 4px;
    box-shadow: 1px 1px 1px 0 lightgray inset;
    padding: 2px 3px;
  }
}

/*!
 * Bootstrap Modal
 *
 * Copyright Jordan Schroter
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */

.modal-open {
  overflow: hidden;
}

/* add a scroll bar to stop page from jerking around */
.modal-open.page-overflow .page-container,
.modal-open.page-overflow .page-container .navbar-fixed-top,
.modal-open.page-overflow .page-container .navbar-fixed-bottom,
.modal-open.page-overflow .modal-scrollable {
  overflow-y: scroll;
}

@media (max-width: 979px) {
  .modal-open.page-overflow .page-container .navbar-fixed-top,
  .modal-open.page-overflow .page-container .navbar-fixed-bottom {
    overflow-y: visible;
  }
}

.modal-scrollable {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

.modal {
  outline: none;
  position: fixed;
  top:0;
  margin-top: 0;
  overflow: visible; /* allow content to popup out (i.e tooltips) */
}


// Modal animations are a scourge to Capybara spec reliability and must be avoided. When hand writing bootstrap
// html the "fade" class should be omitted from the modal element, and when using the <Modal/> React component
// the property `animation={false}` should be specified. However if we inadvertently introduce the "fade" class
// the following disables the animation at the CSS level.
.modal.fade,
.modal-backdrop.fade {
  transition: none !important;
  -o-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -webkit-transition: none !important;

  transform: none !important;
  -o-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -webkit-transform: none !important;

  animation: none !important;
  -o-animation: none !important;
  -moz-animation: none !important;
  -ms-animation: none !important;
  -webkit-animation: none !important;
}

.modal-body {
  max-height: none;
  overflow: visible;
}

.modal.modal-absolute {
  position: absolute;
  z-index: 950;
}

.modal .loading-mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $white;
  border-radius: 6px;
}

.modal-backdrop.modal-absolute {
  position: absolute;
  z-index: 940;
}

/* Modal Overflow */

.modal-body {
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  legend {
    border-bottom:1px solid $gray38;
  }
}


// Rails error highlighting can mess with layout
// of inline form elements.
.modal .field_with_errors {
  display: inline;
}

.row.d-none { display:none; }

.payment-plan-editor-modal {
  .modal-dialog {
    width:600px;
  }
}

/* if the height of the screen is too small, modals can appear off screen  */
@media (max-height: 900px){
  .modal {
    position:fixed;
    top:0 !important; //certain modals get an inline top applied to them dynamically that is overriding this
    margin-top:inherit;
  }
}
