.lottery-presentation {
  .logo {
    position: relative;
    text-align: center;
    max-width: 100%;
    height: auto;
    border-bottom-color: $gray48;
    border-bottom-style: solid;
    border-bottom-width: 10px;
    margin-bottom: 10px;
  }
  .splash {
    h1, h2, h3, h4, h5 {
      text-align: center;
    }

    h3, h4, h5 {
      font-weight: normal;
    }
  }

  .start {
    text-align: center;
    margin-top: 50px;
  }
  .pause {
    padding-bottom: 15px;
    a { margin: 0 1em 0 0; }
    i {
      padding-right: 150px;
      padding-left: 45px;
    }
  }

  .start, .pause {
    font-size: 18px;
    bottom: 0;
    .inner {
      a {
        color: $medium-grey;
        &:hover {
          text-decoration: none;
        }
      }
      i {
        font-size: 64px;
        &:hover {
          text-decoration: none;
        }
      }
      p:hover, .fa.fa-play-circle:hover+p {
        text-decoration: underline;
      }
      .heading {
        margin-top: 22px;
        font-size: 24px
      }
    }
  }

  .results {
    .header {
      height: auto;

    }
    .fixed-height {
      height: 60px;
    }
    .current-student {
      display: block;
      margin: 20px 0 0 40px;
      .student-info {
        color: $black;
        float: left;
        line-height: 48px;
        font-weight: 700;
      }
    }
    .name {
      margin-top: 15px;
      font-size: 48px;
    }
    .birth-date {
      padding: 12px 0 0 40px;
      font-size: 32px;
    }
    .status {
      padding: 0 0 0 40px;
      font-size: 24px;
      font-weight: 300;
      color: $medium-grey;
      line-height: 32px;
    }


    table {
      table-layout: fixed;
      td, th {
        text-align: center;
      }
      .heading {
        background-color: $medium-grey;
        color: white;
        font-size: 18px;
      }
    }
  }
}
