@import '../../shared/helpers/bootstrap_variables';

.pagination-right {
  float: right;
}

.pagination-details {
  display: inline-block;
  margin: 5px 10px 0 0;
  color: $gray1;
}

// For AngularJS links that have no 'href' attribute and thus, no hover action
.pagination a:hover {
  cursor: pointer;
}

.pagination-gap {
  @extend .page-link;

  color: $body-color;
}

.pagination-disabled {
  color: $body-color;
}
