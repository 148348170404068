@import 'app/assets/stylesheets/helpers/globals';

.p-error {
  margin: 40px;

  h1 {
    font-weight: normal;
    color: $orange;
    margin: 0;
    line-height: 36px;
    font-size: 36px;
  }

  h2 {
    line-height: 28px;
    color: $black !important;
    font-weight: normal;
    margin: 0 0 25px;
    font-size: 18px;
  }

  hr {
    border-top: 1px dotted $light-grey;
  }

  .message {
    margin: 0 0 15px;
  }
}