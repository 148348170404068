/*
* — General calendar component styles —
*/

.calendar {
  .fc-event-inner:hover {
    cursor: pointer;
  }

  .fc-sat, .fc-sun {
    background: $gray41;
  }
}

.datepicker.datepicker-dropdown {
  .prev, .next, .datepicker-switch {
    cursor: pointer;
  }
}
/*
* — Styles specific to the admin calendar component —
*/

.admin-calendar {
  .details {
    margin: 10px 0 15px;
  }
}

.booking-notice {
  margin: 12px 0 18px;
}

.schedule-detail-modal {
  .controls {
    input,
    textarea {
      @extend .form-control;
      @extend .form-control-sm;

      width: 206px !important;
    }
  }
  .palettebox {
    div.palette {
      display: none;
    }
    .coloring {
      border: 1px solid $black;
    }
  }
}

.edit-calendar-modal {
  form.calendar-details {

    input[type="text"],
    textarea {
      @extend .form-control;
      @extend .form-control-sm;
    }

    select {
      @extend .form-select;
      @extend .form-select-sm;
    }

    legend {
      font-size: 19.5px;
    }
  }
}

.edit-calendar-modal {
  form.calendar-details {

    input[type="text"],
    textarea {
      @extend .form-control;
      @extend .form-control-sm;
    }

    select {
      @extend .form-select;
      @extend .form-select-sm;
    }

    legend {
      font-size: 19.5px;
    }
  }
}

.edit-calendar-modal {
  form.calendar-details {

    input[type="text"],
    textarea {
      @extend .form-control;
      @extend .form-control-sm;
    }

    select {
      @extend .form-select;
      @extend .form-select-sm;
    }

    legend {
      font-size: 19.5px;
    }
  }
}

.modal.edit-available-time {

  section.recurrence-fields{
    .col-form-label {
      display:flex;
      flex:4 1 0px;
      justify-content: flex-end;
      align-self:flex-start;
      padding:0;
    }

    .controls {
      flex:10 10 0px;
    }
  }
  ul.recurrence-list {
    display:flex;
    padding-left:0;

    li {
      list-style:none; 
      margin-right:10px;
    }
  }

  .modal-dialog {
    max-width:700px;
  }

  .timeframe-inputs{

    div.row {
      >div {
        padding-left:3px;
        padding-right:3px;

        &:first-of-type {
          margin-left:13px;
        }
      }
    }    
  }

  #available_time_capacity {
    display: inline;
    width: auto;
  }
}

.modal.show-available-time {
  .booked-appointments {
    margin-top: 10px;
    ul { padding-left: 16px; margin: 8px 0; }
    li { list-style: none; }
  }

  .capacity-detail {
    padding: 8px 0;
  }
}

.appointment {
  .time-detail {
    font-size: 1.3em;
    line-height: 1.4em;
    margin: 0 0 .5em 4%;
  }

  iframe {
    border: none;
    overflow: hidden;
    width: 100%;
    display: block;
  }

  .appointment-spinner {
    display: block;
    margin: 10px auto;
  }
}

.delete-schedule-modal {
  .move-appointments-form {
    margin: 10px 0 0;
  }
}
