.notes {
  color: $medium-grey;

  .filter-labels {
    margin: 2px 0 5px 0px;
    padding-right: 0;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin: 0 0 15px 0;
      padding: 10px;
      @include rounded-corners();

      &.activity {
        background: $gray62;

        .type-name {
          color: $blue;
        }
      }

      .note-details {

        .note-details-title {
          text-align: left;
          font-style: italic;

          span {
            font-style: italic;
          }
        }

        .note-details-date {
          text-align: right;
        }

        .note-details-title,
        .note-details-date {
          @include make-col(12);
        }

      }

      &.note {
        background: $gray63;

        .type-name {
          color: $teal;
        }
      }

      &.email {
        background: $green5;

        .type-name {
          color: $green;
        }
      }

      &.mms {
        background: $pink1;

        .type-name {
          color: $red;
        }
      }

      .content-container {
        margin: 10px 0;

        .content {
          margin: 10px 0;
          color: $black;
        }
      }

      .date {
        font-weight: 300;
      }

      .delete {
        margin-left: auto;
        font-size: 14px;
        cursor: pointer;
      }

      .row{
        > * {
          width: auto; // Overrides bootstrap setting this to 100%
        }
      }

      .editable, .editable-open {
        border-bottom: 1px solid transparent;

        &:hover, .editable-open {
          cursor: pointer;
          border-bottom: 1px dotted $medium-grey;
        }
      }

      .visibility {
        i {
          font-size: 15px;
          line-height: 8px;
        }
      }
    }
  }

  .add-note {
    margin: 0 0 10px 0;
  }

  .filters {
    width: auto;
    margin: 0 0 0 10px;
    font-size: 18px;
    i {
      font-size: 15px;
      span {
        font-size: 18px;
      }
    }

    .btn {
      margin: 0 -5px 5px 0;
      padding: 3px 12px 3px 12px;
      background-color: transparent;
      &:hover {
        cursor: pointer;
      }
      color: $medium-grey;
    }
    .selected-all {
      background-color: $medium-grey;
      text-shadow: none;
      color: white;
      &:hover {
        cursor: default;
      }
    }
    .selected-activity {
      background-color: $blue;
      &:hover {
        cursor: default;
      }
      span, i {
        text-shadow: none;
        color: white;
      }
    }
    .selected-note {
      background-color: $teal;
      &:hover {
        cursor: default;
      }
      span, i {
        text-shadow: none;
        color: white;
      }
    }
    .selected-email {
      background-color: $green;
      &:hover {
        cursor: default;
      }
      span, i {
        text-shadow: none;
        color: white;
      }
    }

    .selected-mms {
      background-color: $red;
      &:hover {
        cursor: default;
      }
      span, i {
        text-shadow: none;
        color: white;
      }
    }
  }
}


.note-content-popover{
  max-width: none;

  textarea.input-large {
    display: block;
    width: 200px;
    min-height: 110px;
  }
}
