.schedule-list {
  margin: 3px 0 0;
  ul {
    margin: 0 0 9px 19px;
  }

  li {
    margin: 0 0 5px;
    a {
      text-decoration: none;
      color: $black;
    }
    .edit {
      margin: 0 0 0 5px;
      a {
        color: $blue;
      }
    }
  }
}
