.internal {

  .requests-table {

    tr.request:hover {
      background-color: $gray70;
    }

    tr.request-detail {
      display: none;

      .col-form-label {
        font-weight: bold;
      }

      .controls {
        padding-top: 5px;
      }
    }

    th, td {
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    td.skinny-column {
      max-width: 160px;
    }
  }

  .scrollable {
    overflow: visible;
    overflow-x: auto;
    width: 100%;
  }

  .audits-table {
    min-width: 800px;


    th, td {
      font-size: 13px;
      white-space: pre-wrap;
      border: 1px solid grey;
      padding: 0 10px 6px 10px;
    }

    td.bold {
      text-align: left;
      font-weight: bold;
      white-space: normal;
      border: 0px solid grey;
    }

    td.right-align {
      font-style: italic;
      text-align: right;
      word-wrap: break-word;
    }
  }
}