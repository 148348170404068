.manage-lotteries-page {
    .row {
        margin-bottom:15px;

        >.form-label {
            @include make-col(4);
            text-align:right;
        }
        >.controls {
            @include make-col(20);
        }
    }
}