.contact-filters {
  display: none;

  @extend .card;
}

.contact-filter-multiselect {
  width: 50%;
  float: left;
}

.contact-filter-multiselect select {
  width: 95%;
}

// Must be here to increase specificity
.admin #container .contact-filter-multiselect hr {
  margin-right: 15px;
}

// This will select the second multiselect of a row
.admin #container .contact-filter-multiselect + .contact-filter-multiselect hr {
  margin-right: 0;
}

.contact-filter-header {
  font-weight: bold;
}

.contact-filter-buttons {
  clear: both;
  padding: 15px 0 0 0;
}

.contact-filter-control {
  margin-bottom: .75rem;
  padding-bottom: .75rem;
  border-bottom: 1px solid $gray3;
}