.new-household {
  .sa-form-sub-field {
    position: relative;
    min-height: 1px;
    padding-left:  4px;
    padding-right: 18px;
    display: inline-block;

    input {
      width: 100%;
    }

    .form-text {
      margin-top: 0;
    }
  }

  .sa-form-sub-field[data-flavor='address.address_1'], .sa-form-sub-field[data-flavor='address.address_2'] {
    display: block;
    clear: none;
  }

  .sa-form-sub-field[data-flavor='address.country'] {
    clear: both;
    display: block;
  }

  .sa-form-sub-field[data-flavor='address.city'] {
    width: 33%;
    clear: left;
  }

  .sa-form-sub-field[data-flavor='address.state'] {
    width: 33%;
    clear: none;
  }

  .sa-form-sub-field[data-flavor='address.zip'] {
    width: 33%;
    clear: right;
  }

  .Select-control {
    height: 28px;
    min-height: 28px;

    .Select-placeholder {
      line-height: 26px;
    }

    .Select-value {
      line-height: 26px !important;

      .Select-value-label {
        line-height: 26px;
      }
    }

    .Select-input {
      height: 26px;
    }
  }
}