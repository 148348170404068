/*------------------------------------*\
 	ADMISSIONS FUNNEL
\*------------------------------------*/

.admissions-funnel {
  height: 285px;
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  width: 85%;

  .admissions-funnel-col {
    width: 46%;

    h5 {
      font-size: 16px;
      padding-bottom: 6px;
      font-weight: bold;
      line-height: 1.3;
      margin-bottom: 0px;
      font-weight: 500;
    }
  }

  .admissions-funnel-col.left h5 {
    text-align: right;
  }

  .admissions-graph-holder {
    min-height: 216px;
  }

  .admissions-funnel-col.right .progress-bar {
    right: auto;
    left: 0;
    //box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    @include rounded-right-corners();
  }

  .admissions-funnel-col.left .progress-bar {
    left: auto;
    right: 0;
    overflow:visible;
    //box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    @include rounded-left-corners();
  }

  .admissions-funnel-col.right .graph-bar-label {
    width: 15px;
    right: auto;
    left: 100%;
    p {
      right: auto;
      left: 100%;
      padding-left: 5px;
      padding-right: 0;
    }
  }

  .graph-bar {
    height: 31px;
    position: relative;
    margin-top: 5px;
    .progress-bar {
      position: absolute;
      top: 0;
      right: 0;
      height: 31px;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .graph-bar-label {
    height: 0;
    position: absolute;
    top: 15px;
    right: 0;
    border-top: 1px dotted $gray55;
    width: 50px;
    color:rgb(12, 55, 101);
    p {
      position: absolute;
      top: -6px;
      right: 100%;
      font-size: 10px;
      color: $gray56;
      line-height: 1;
      text-transform: uppercase;
      white-space: nowrap;
      padding-right: 5px;
    }
    strong {
      position: absolute;
      top: 7px;
      right: 100%;
      width: 44px;
      height: 18px;
      background: $gray57;
      border-radius: 5px;
      line-height: 18px;
      text-align: center;
      color: $gray56;
      font-size: 12px;
    }
  }

  .connected-line-horizontal {
    height: 0;
    position: absolute;
    top: 15px;
    right: 0;
    border-top: 1px dotted $gray55;
  }

  .connected-line-vertical {
    width: 0;
    position: absolute;
    top: -1px;
    left: -1px;
    border-left: 1px dotted $gray55;
    strong {
      position: absolute;
      top: 50%;
      left: -28px;
      margin-top: -12px;
      width: 55px;
      height: 24px;
      background: $gray55;
      border-radius: 5px;
      line-height: 24px;
      text-align: center;
      color: $white;
      font-size: 14px;
    }
  }

  .x-labels {
    overflow: hidden;
    padding-left: 17px;
    margin-top: -18px;
    span {
      float: left;
      color: $gray58;
      font-size: 9px;
      text-align: center;
      width: 35px;
      margin-left: 1px;
    }
  }

  h5.current-students {
    margin-left: 8px;
  }

  h4.current-counts-label {
    margin-top: 10px;
  }

  .current-count-popup {
    width: 280px;
    font-size: 14px;
    color: $gray56;
    line-height: 20px;
    padding: 28px 15px 18px;
    strong {
      color: $black1;
    }
    .buttons {
      height: 26px;
      padding-top: 20px;
    }
    .cancel {
      color: $gray55;
      font-size: 12px;
      line-height: 26px;
      text-decoration: underline;
    }
    .cancel:hover {
      text-decoration: none;
    }
    .btn {
      height: 24px;
      border: 1px solid $blue18;
      border-radius: 4px;
      color: $white;
      line-height: 24px;
      padding: 0 10px;
    }
    .btn:hover {
      color: $black1;
      text-decoration: none;
    }
  }

  .admissions-graph-stats {
    list-style: none outside none;
    width: 8%;
    color: $gray59;
    font-size: 18px;
    line-height: 29px;
    text-align: center;
    font-weight: bold;
    padding: 27px 0px 0px 0px;
    margin: 0;
    li {
      border-width: 1px 0;
      border-style: dotted;
      border-color: $gray55;
      margin: 4px 0 5px 0;
      height: 31px;
      display:flex;
      align-items:center;
      justify-content:center;
    }
    a {
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 1150px) {
    .admissions-graph-stats {
      font-size: 14px;
    }
  }
}

.current-counts {
  padding: 5px 0px 20px 0px;
  overflow: hidden;
  height: 100%;
  h2 {
    font-size: 18px;
    font-weight: normal;
    padding: 0;
    color: $black2;
  }
  .col {
    width: 151px;
    float: left;
  }
  .col.pull-right {
    float: right;
  }
  .bar, .progress-bar {
    height: 11px;
    position: relative;
  }
  .circle {
    position: absolute;
    top: 6px;
    left: 50%;
    margin-left: -5px;
    width: 8px;
    height: 8px;
    background: $white;
    border-width: 1px;
    border-style: solid;
    border-radius: 15px;
  }
  .box {
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
    margin: 0 5px;
    padding: 9px 0;
    span:hover {
      text-decoration: underline;
    }
  }
  .title {
    font-weight: bold;
    overflow: hidden;
    display: block;
    font-size: 12px;
    color: $gray60;
    text-transform: uppercase;
    line-height: 14px;
    text-align: right;
    margin: 0 10px;
    span {
      display: inline-block;
      width: 100%;
      text-align: center;
    }
  }
  .title:hover span {
    text-decoration: underline;
    font-weight: bold;
    color: $gray60;
  }
  ul {
    list-style: none outside none;
    padding: 9px 0px 0px 0px;
    margin: 0px 10px;
  }
  li {
    color: $gray58;
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    padding-bottom: 9px;
    a:hover {
      text-decoration: underline;
    }
    span:first-child {
      float: left;
      width: 75%;
      text-align: right;
      + span {
        float: right;
        width: 20%;
        text-align: right;
      }
    }
  }
  .divider {
    height: 14px;
    width: 1px;
    margin: 0 auto;
    display: block;

    &:before {
      content: "";
      padding: 0;
    }
  }

  .yellow0 {
    .bar, .progress-bar, .divider {
      background: $orange7;
    }
    .circle, .box {
      border-color: $gray8;
    }
    .title:hover {
      color: $gray8;
    }
  }

  .yellow1 {
    .bar, .progress-bar, .divider {
      background: $orange8;
    }
    .circle, .box {
      border-color: $gray8;
    }
    .title:hover {
      color: $gray8;
    }
  }
}

.dashboard-counts {
  .blue0 {
    .bar, .progress-bar, .divider {
      background: $blue20;
    }
    .circle, .box {
      border-color: $gray8;
    }
    .title:hover {
      color: $gray8;
    }
  }

  .blue1 {
    .bar, .progress-bar, .divider {
      background: $blue21;
    }
    .circle, .box {
      border-color: $gray8;
    }
    .title:hover {
      color: $gray8;
    }
  }

  .blue2 {
    .bar, .progress-bar, .divider {
      background: $blue22;
    }
    .circle, .box {
      border-color: $gray8;
    }
    .title:hover {
      color: $gray8;
    }
  }

  .blue3 {
    .bar, .progress-bar, .divider {
      background: $blue23;
    }
    .circle, .box {
      border-color: $gray8;
    }
    .title:hover {
      color: $gray8;
    }
  }

  .blue4 {
    .bar, .progress-bar, .divider {
      background: $blue9;
    }
    .circle, .box {
      border-color: $gray8;
    }
    .title:hover {
      color: $gray8;
    }
  }

  .blue5 {
    .bar, .progress-bar, .divider {
      background: $green17;
    }
    .circle, .box {
      border-color: $gray8;
    }
    .title:hover {
      color: $gray8;
    }
  }
}
