.checklist {
  margin: 0 0 25px 0;
  .lists {
    h3 {
      color: $medium-grey;
      font-size: 18px;
      font-weight: normal;
    }

    .progress {
      margin: 0;
    }

    .optional-items {
      margin-top: 8px;
    }

    .checklist-items {
      margin: -1px 0 0 0;

      ul {
        margin: 0;
        padding: 0;

        li + li {
          border-top: 1px solid $divider;
        }

        li:last-child {
          border-bottom: 1px solid $divider;
        }

        .checklist-sub-item {
          border-top: 1px dotted $divider;
          margin-top: 5px;
          padding: 8px 0 0 0;
        }

        li {
          list-style: none;
          padding: 13px 0 9px 0;

          input[type='checkbox'] {
            display: none;
          }

          .box {
            color: $medium-grey;
            font-size: 18px;
            padding: 0 10px 0 0;
            text-align: right;

            &.disabled {
              color: $gray34;
              pointer-events: none;
              cursor: default;
            }

            i:hover {
              cursor: pointer;
            }
          }

          .item {
            .delete-file-link{
              display: none;

            }
            &:hover {
              .delete-file-link {
                display: inline;

                &:hover {
                  text-decoration: none;
                }
              }
            }

            .second-row {
              border-top: 1px dotted $divider;
              margin-top: 5px;
              padding: 8px 0 0 0;

              a.disabled {
                pointer-events: none;
                cursor: default;
                color: inherit;
              }
            }

            .date {
              min-height: 0;
            }

            .action {
              text-align: right;
              min-height: 0;

              i[class*='fa-'] {
                font-size: 14px;
              }

              /* progress bar used by file upload */
              .progress {
                float: right;
                width: 160px;
              }
            }
          }
        }
      }
    }
  }
}

.checklist-item-edit-criterion {
  .mb-3 {
    margin-top: 18px;
  }
}

.crop-modal {
  min-width: 700px;

  .fileinput-button, .progress, .progress-bar {
    display: inline-block;
    vertical-align: bottom;
  }

  .progress {
    display: inline-block;
    width: 153px;
    margin: 0;
    padding: 7px 12px;
    vertical-align: middle;
    box-shadow: none;
  }

  .fileinput-button input {
    // Sized to fit the BS button
    top: -7px;
    width: 100%;
    height: 32px;
    transform: none;
  }

  .crop-dialog {
    text-align: left;
    img {
      // This rule is required for Jcrop to work in a Bootstrap modal!
      max-width: none;
    }
    .left-pane {
      display: inline-block;
      margin: 0;
      width: 66%;
      text-align: center;
      .original {
        max-width: 400px;
        max-height: 300px;
      }
      .jcrop-holder {
        margin: 0 auto 5px auto;
      }
      .image-rotate button {
        margin: 0 2px 10px 2px;
      }
    }
    .right-pane {
      margin: -59px 0 0 0;
      display: inline-block;
      position: absolute;
      top: 50%;
      text-align: center;
      width: 33%;
      .preview {
        margin: auto auto 5px auto;
        overflow: hidden;
        width: 100px;
        height: 100px;
      }
    }
  }
}

.fileinput-with-form{
  display: inline-block;
  float: right;
  padding: 0 0 0 6px;
}

.spacer{
  display: inline-block;
  padding: 0 0px 0px 5px;
}
