.lock-control {
  color: $black;
  cursor: pointer;

  &::before {
    margin: 0 0.5em;
    font-size: 18px;
  }

  &::after {
    color: $medium-grey;
    font-style: italic;
  }

  &[data-state="locked"] {
    &::before {
      @include fa-icon();
      content: $fa-var-lock;
    }
    &::after {
      content: attr(data-locked-text);
    }
  }

  &[data-state="unlocked"] {
    &::before {
      @include fa-icon();
      content: $fa-var-unlock;
    }
    &::after {
      content: attr(data-unlocked-text);
    }
  }
}

.lockable.locked:not(.hidelock) {
  opacity: 0.5;
  &:hover {
    text-decoration: none;
  }
}
