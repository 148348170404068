@import '../../shared/helpers/bootstrap_variables';

.base-setup-header {
  margin: 0 0 25px 0;
}

.base-setup-fields {
  max-width: 1200px;
  margin: 0 0 50px 0;
}

.base-setup-field-group {
  @extend .row;

  margin-bottom: 15px;
}

.base-setup-field-label, .base-setup-field-checkbox-label, .base-setup-field-inline-label, .base-setup-small-field-label {
  color: $gray8;
}

.base-setup-field-checkbox-label {
  flex: 1;
};

.base-setup-field-inline-label {
  flex: 0 0 auto;
  width: auto;
};

.base-setup-small-field-label {
  @extend .col-3;
}

.base-setup-field-checkbox-value {
  flex: 0 0 25px;
  padding-right: 0;
}

.base-setup-field-helper {
  @extend .form-text;

  margin-left: 5px;
}

.base-setup-field-value {
  @extend .col-6;
}

.base-setup-field-small-value {
  @extend .col-3;
}

.base-setup-field-input {
  @extend .form-control;
  @extend .form-control-sm;
}

.base-setup-field-select {
  @extend .form-select;
  @extend .form-select-sm;
}

.base-setup-field-plaintext {
  @extend .form-control-plaintext;
}

.base-setup-field-file {
  padding: 10px 0 10px 15px
}
