/* sa_form_fields.scss

  For sa-form fields used in the admin portal. This is meant to bridge the gap while the admin portal and the parent
  portal use separate assets.

*/

[class^=sa-form-] > input, [class^=sa-form-] > select, [class^=sa-form-] > textarea {
  margin-bottom: 0;
}


.sa-form-sub-field-label {
  font-weight: normal;
  font-size: 11px;
}