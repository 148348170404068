.setup {
  &.contact-layout {
    th {
      white-space: nowrap;
    }
    .canonical-name {
      display: block;
      font-size: 11px;
      color: gray;
    }
    tr:hover .role-name {
      visibility: visible;
    }
    .role-name {
      visibility: hidden;
      font-size: 11px;
      color: gray;
    }
  }
}

.failed-email-resend-modal {
  .mb-3.disabled {
    input, label {
      color: $gray14;
    }
  }
}

.lottery-report {

  h2 {
    color: $gray35;
    margin: 12px 0;
  }

  .lottery-report-header {
    .row {
      padding-bottom: 6px;
    }
  }

  .lottery-results-header {
    display: flex;
  }

  .reveal-contact-names {
    box-shadow: none;
  }
}

// Special styling for payment history page
#content #manage-orders.setup {
  // High specificity to beat admin_portal.scss .search-button rules
  .search-button.toggle-filters {
    background-color:$gray6;
    padding:5px 11px;
    color:$white;
    border-radius:3px;
    font-style: normal;

    &:hover {
      background-color: $gray0;
      color: $white;
    }

    i{
      margin-left: 8px;
    }
  }

  .search-content {
    #search_range,
    button.btn.btn-sm {
      margin-left: 2px;
      height: 31px;
      vertical-align: top;
    }

    .input-small,
    .input-medium {
      display: inline-block;
      margin-left: 2px;
      width: 110px;
    }

    .input-medium {
      width: 150px;
    }
  }
}

// Special styling for non-fee billing category page
.non-fee-billing {
  h2 {
    display: inline;
  }

  i.fa.fa-info-circle {
    color: $light-grey;
  }
}

.form-fees-modal {
  .note-select-charge-type {
    padding-top: 5px;
    font-style: italic;
  }
}

.contract-fee-canonical-name {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.setup-home {
  .widget-container {
    display: flex;
    flex-direction: row;

    .widget {
      flex: 1;
      padding: 0 1em;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}
