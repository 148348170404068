@import '../shared/helpers/bootstrap_variables';

.admin #container {
  min-height: 100%;
  min-width: 1280px;
  position: relative;

  #ck_fu .screen-sizes {
    display: none;
  }

  .prepend-header {
    line-height: 28px;
    // FIX ME
    float: left;
    margin: 0 4px 0;

    &:after {
      content: '|';
    }

    .fa.fa-chevron-left {
      font-size: 15px;
    }
  }

  footer {
    color: $gray8;
    border-top: 1px solid $gray76;
    font-size: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    line-height: 15px;

    #footer {
      margin: 15px 0;
    }

    .trademark-section {
      text-align:right;

      span {
        color: $black2;
      }

      a {
        text-decoration:underline;
      }

      .rights-reserved {
        margin-top:3px;
      }
    }
  }

  .form-left {
    label {
      float: left;
      margin: 5px 5px 0 0;
    }
  }

  .add-item-button, .search-button {
    display: inline;
    padding: 0 0 0 5px;

    &:hover {
      cursor: pointer;
    }
  }

  .search-button {
    &:not(i):not([class*="fa-"]) {
      font-style: italic;
    }
  }

  .add-item-button {
    color: $green6;
    font-size: 14px;
    margin: 5px 0 10px 0;
    display: inline-block;
    font-style:normal;

    &:hover {
      text-decoration: none;
      color: $green6;
    }
  }

  ul, ol {
    &.action-list {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;

      li {
        float: left;
        margin-right: 4px;
        list-style-type: none;
      }

      li a {
        margin-right: 4px;
      }

      li:after {
        color: gray;
        content: '|';
      }

      li:last-child:after {
        content: '';
      }
    }
  }

  .stages {
    margin: 0;
    padding: 0;
    display: inline-block;
    overflow: hidden;
    background: url('../../images/shared_components/tab_bg.png') repeat-x bottom;

    li {
      float: left;
      list-style: none;
      margin-right: 30px;
      min-height: 28px;
      margin-bottom: 1px;

      &.active {
        background: url('../../images/shared_components/tab_caret_down.png') no-repeat bottom center;

        a {
          color: $teal2;
          font-weight: bold;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  /*------------------------------------*\
      Links
  \*------------------------------------*/

  .assistive-link {
    color: $assistive-link;
  }

  .danger-link {
    color: $danger-link;
  }

  .inline-link {
    color: $inline-link;
    border-bottom: 1px dotted $light-grey;

    &:hover {
      color: $inline-link;
      text-decoration: none;
    }
  }

  .mini-link {
    color: $mini-link;
  }

  .editable {
    border-bottom: 1px dotted $light-grey;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  /*------------------------------------*\
      General
  \*------------------------------------*/

  #content {
    padding: 25px 0;
    padding-bottom: 106px;
    min-height: 100px;

    .title-row {
      min-height: 31px; // To ensure height, even if year select isn't present
    }

    .add-item-row {
      line-height: 15px;
      margin-bottom: 5px;
      min-height: 16px; // To ensure height, even if add item button isn't present

      .add-item-button {
        margin: 0;
      }
    }
  }

  .container-fluid {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    min-width: 940px;
    padding-left: 40px;
    padding-right: 40px;
  }

}

h1 {
  color: $light-grey;
}

h2 {
  color: $teal2;
  font-weight: normal;
  line-height: 28px;
}

h3 {
  color: $medium-grey;
}

.popover-body {
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

legend {
  border-bottom: 1px solid $gray38;
  color: $teal2;
  font-size: 16.5px;
  line-height: 24px;
  margin-bottom: 1rem;
}
