.contract-fee-list {
  h3 {
    font-weight: normal;
    margin: 20px 0 0 0;
    line-height: 24px;
  }

  .line-items {
    .item {
      padding: 3px 1px 0 0;
      border-bottom: 1px solid $divider;
      overflow: hidden;
      line-height: 24px;

      input {
        margin-left: 0px;
      }

      .description {
        font-weight: bold;
        margin-left:20px;
      }

      .fee {
        text-align: right;
      }
    }


  }

  .optional-fees, .payment-plans {
    .line-items {
      .item {
        &.deselected {
          color: $medium-grey;
        }
      }
    }
  }

  .total {
    margin: 35px 0 0 0;

    .line-items {
      .item {
        border: 0;
        font-size: 16px;

        .description {
          font-weight: normal;
        }

        .fee {
          font-weight: bold;
        }
      }
    }
  }
}