.teacher-rec-modal .tr-admin-stacked-text-box {
  padding: 0 0 5px 0;
}

.teacher-rec-modal label.col-form-label {
  text-align: end;
}

.tr-admin-header {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 10px;
}
