.enrollment-and-admissions,
.analytics {
    padding-bottom: 16px;

    .row {

        margin: 15px 0 0 15px;

        .col-form-label {
            @include make-col(6);
            justify-content: flex-start;
        }

        .controls {
            @include make-col(18);
            text-align: left;

        }
    }

    .btn {
        margin-top: 15px;
    }
}
