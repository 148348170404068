@import '../shared/helpers/bootstrap_variables';

.action-buttons {
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: flex-end;

  > li {
    > a {
      margin: 0 5px;
      cursor: pointer;
      background: $green2;
      padding: 5px 10px;
      border-radius: 3px;
      color: $white;

      &:hover {
        color: $white;
      }

      &.disabled {
        color: $gray2;
        background: $gray4;
        cursor: default;
      }
    }
  }
}
