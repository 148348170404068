.contact-list {
  th a {
    color: $black;
  }
  .currency{
    text-align: right;
  }
  .contact-notes {
    margin-left: 10px;

    .popover {
      font-family: $font-family-base;
    }
    .popover .popover-content {
      font-size: 13px;
      line-height: 18px;
      width: 250px;
    }
  }

  .progress {
    .popover {
      .popover-body {

        h3 {
          font-size: 14px;
          color: $medium-grey;
          line-height: 36px;
        }

        ul {
          margin: 0;
          padding-left: 0;
          list-style: none;

          li {
            line-height: 24px;

            i {
              margin-top: -3px;
              color: $medium-grey;
            }
          }
        }
      }
    }
  }
}

#generate-contracts-wait-modal {
  .modal-body {
    font-size: 18px;
  }

  .row + .row {
    margin-top: 20px;
  }
}
