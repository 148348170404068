@mixin rounded-corners ($radius: 5px) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

@mixin rounded-left-corners ($radius: 5px) {
  -moz-border-radius-bottomleft: $radius;
  -moz-border-radius-topleft: $radius;
  -webkit-border-bottom-left-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
  border-top-left-radius: $radius;
}

@mixin rounded-right-corners ($radius: 5px) {
  -moz-border-radius-bottomright: $radius;
  -moz-border-radius-topright: $radius;
  -webkit-border-bottom-right-radius: $radius;
  -webkit-border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin rounded-top-corners ($radius: 5px) {
  -moz-border-radius-topright: $radius;
  -moz-border-radius-topleft: $radius;
  -webkit-border-top-right-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
}

@mixin rounded-top-right-corner ($radius: 5px) {
  -moz-border-radius-topright: $radius;
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin rounded-bottom-corners ($radius: 5px) {
  -moz-border-radius-bottomright: $radius;
  -moz-border-radius-bottomleft: $radius;
  -webkit-border-bottom-right-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder { /* Firefox 18- */
    @content;
  }
  &::-moz-placeholder {  /* Firefox 19+ */
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin make-column($columns, $gutter: $gridGutterWidth) {
  position: relative;
  min-height: 1px;
  margin-left:  ($gutter / -2);
  margin-right: ($gutter / -2);
  float: left;
  width: percentage(($columns / 24));
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
