.list {
  &.table-bulk {
    input[type="radio"], input[type="checkbox"] {
      margin: 0 0 2px 0;
    }
  }

  th {
    margin: 3px 0;
    white-space: nowrap;
  }

  th, td {
    padding-top: 10px;
    vertical-align: middle;
    line-height: 13px;

    input[type="checkbox"] {
      margin-top: -1px;
    }
  }

  td {
    [class^="fa-"], [class*=" fa-"] {
      font-size: 18px;
      vertical-align: text-top;
    }

    a {
      color: $inline-link;

      &:hover {
        text-decoration: underline;
      }

      &.icon {
        color: $regular-link;

        &:hover {
          text-decoration: none;
        }
      }
    }
    .small {
        font-size: 13px;
        position: relative;
        top: 1px;
    }
  }
    .show-bulk-bar > th, td {
        min-width: 73px;
    }

  .bulk-action-checkbox {
    min-width: 15px;
  }

  .progress {
    margin-bottom: 0px;
  }

  .inactive-checklist-progress {
    color: $light-grey;
  }
}

#grades-list, #calendars-list {
  .removed-grades {
    button.removed-grades-button {
      margin-bottom: 10px;
    }
  }
  td, th {
    vertical-align: middle;
    a {
      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
      &.remove-grade, &.remove-calendar {
        color: $danger-link;
      }
    }
  .make-switch {
    font-weight: bold;
    }
  }
}