.checkbox-list {
  .box {
    float: left;
  }

  .checkbox-label {
    float: left;
    margin: 0;
    padding: 4px 0px 2px 5px;
  }
}