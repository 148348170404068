@import 'app/assets/stylesheets/helpers/globals';

.update-fields-modal {
  .chosen-container .chosen-results .group-result {
    border-top: 1px solid $grey4;
    padding-top: 10px;
    margin-top: 4px;
    text-transform: uppercase;
    font-weight: bold;
    color: $gray31;
  }

  .hidden {
    display: none !important;
  }

  select + input {
    margin-top: 8px;
  }

  label {
    color: $gray67;
  }

  .update-fields-confirmation {
    p:last-child {
      margin-bottom: 0;
    }
  }

  .update-fields-warning {
    color: $red1;
  }

  .update-fields-notice {
    font-weight: bold;
    color: $grey0;
  }
}