.update-fields-modal {
    .row {
        margin-bottom:15px;

        >label {
            @include make-col(8);
            flex:0 0 auto !important;
            text-align:right;
        }
        >.controls {
            @include make-col(16);
            flex:0 0 auto !important;
        }
    }
}