@import '../shared/helpers/bootstrap_variables';

#container .contact {
  .editable {
    border: 1px solid transparent;

    &:hover{
      border-bottom: 1px dotted $gray2;
    }
  }

  .contact-checklist {
    li > a {
      color: $gray6;

      &:hover {
        color: $gray31;
      }
    }
  }

  .editable-form {
    display: inline;

    & .form-control {
      display: inline;
    }
  }

  .docs {
    padding-top: 7px;
    .forms, .uploads {
      margin: 20px 0 0 0;
    }

    .light-grey {
      color: $light-grey;
    }
  }
  .content-bubble {
    margin: 5px;
    padding: 5px;
  }

  @mixin bottom-divider {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: $divider;
  }

  .bottom-divider {
    @include bottom-divider;
  }

  @mixin contact-links {
    a {
      text-decoration: none;
      &.action {
        visibility: hidden;
      }
      &.active {
        cursor: default;
        color: $inline-link;
        font-weight: bold;
      }
    }
  }
  .contact-links {
    @include contact-links;
  }

  .related-contacts {
    ul {
      border-top: 1px solid $divider;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        border-bottom: 1px solid $divider;
        line-height: 18px;
        padding: 10px 10px 5px 10px;

        &:hover {
          background: $lighter-grey;
          cursor: pointer;
        }

        [class*="fa-chevron"] {
          line-height: 13px;
          font-size: 18px;
          color: $medium-grey;
          cursor: pointer;
        }
        .details {
          margin: 0 5px 9px 0;
        }
        .more {
          display: none;

          .editable-form {
            width: 190px;

            input[type='email'] {
              width: 170px;
            }

            .editable-phone {
              input[type='text'] {
                width: 100px;
              }

              select {
                width: 70px;
              }
            }
          }
        }
      }
    }

    .add-related-contact {
      margin-bottom: 10px;

      color: $medium-grey;

      h3 {
        margin: 0 0 0 15px;
        line-height: 24px;
        font-weight: normal;
      }
    }
  }

  .key-information {
    padding: 0 0 35px 0;
    border-bottom: 1px dotted $divider;
    margin: 0 0 35px 0;
  }

  .entry {
    @include rounded-corners();
    border: 1px dotted $divider;
    margin: 0 0 5px 0;
    padding: 5px 10px 10px 0;

    .form-horizontal {
      position: relative;

      & > .row {
        margin-top: 5px;

        & > div > .row > .btn {
          padding: 5px 0;
        }
      }

      .cancel {
        float: right;
      }

      .error {
        border-color: $red11;
      }
    }
  }

  .fields-page {
    .btn-info {
      margin: 0 0 0 5px;
    }
    .edit-buttons {
      a {
        color: $medium-grey;
        font-weight: normal;
      }
    }
    .grouping, .col-12.mb-3 {
      margin-bottom: 50px;
    }
    .group {
      h2.title {
        display: block;
        color: $body-color;
        margin: 0 0 15px 0;
        padding: 10px 0 2px 0;
        line-height: 21px;
        font-weight: 500;
        font-size: 21px;
        @include bottom-divider;

      }
      .field {
        margin: 5px 0 0 0;
        .prompt {
          color: $medium-grey;
          text-align: right;
          label {
            margin-top: 5px;
          }
        }
        .editable-container {
          padding: 0 0 0 5px;
          margin: -5px 0 0 0;
        }
        .editable-empty {
          color: $medium-grey;
        }
        .value {
          .value-link {
            margin-top: 5px;
            font-weight: normal;
            font-style: italic;
            a {
              padding-top: 5px;
              &:hover {
                text-decoration: none;
              }
            }
          }
          font-weight: 700;
          color: $body-color;
          .sub-field-label {
            margin-top: -5px;
            font-size: 11px;
            line-height: 13px;
          }
          table {
            margin-top: 5px;
          }
          min-height: 18px;
          ul {
            list-style-type: none;
            margin: 0;
            padding-left: 0
          }
        }
      }
    }
  }

  .history-container {
    a {
      text-decoration: none;
      &:hover {
        cursor: pointer;
      }
      .colored-link {
        color: $orange;
      }
    }
    .status {
      &.color {
        color: $orange;
      }
      &.bg {
        background-color: $gray42;
      }
      &.sa-border {
        border-color: $gray42;
      }
    }
    .fields {
      &.color {
        color: $orange4;
      }
      &.bg {
        background-color: $gray43;
      }
      &.sa-border {
        border-color: $gray43;
      }
    }
    .checklist {
      margin: 0;
      .text {
        margin-left: -9px;
      }
      &.color {
        color: $indigo0;
      }
      &.bg {
        background-color: $gray44;
      }
      &.sa-border {
        border-color: $gray44;
      }
    }
    .activity {
      &.color {
        color: $blue;
      }
      &.bg {
        background-color: $gray45;
      }
      &.sa-border {
        border-color: $gray45;
      }
    }
    .email {
      &.color {
        color: $green;
      }
      &.bg {
        background-color: $gray46;
      }
      &.sa-border {
        border-color: $gray46;
      }
    }
    .note {
      &.color {
        color: $teal;
      }
      &.bg {
        background-color: $gray47;
      }
      &.sa-border {
        border-color: $gray47;
      }
    }

    .filter-histories {
      .fa-lg {
        vertical-align: text-bottom;
        margin: 0 5px 0 5px;
        text-align: center;
      }
    }
    .filter {
      position: relative;
      bottom: 2px;
    }

    .history-entry {
      border-style: solid;
      @include rounded-corners;
      margin: 5px 5px 5px 0;
      max-width: 100%;

      div.content {
        overflow: hidden;
      }

      .icon {
        position: relative;

        text-align: center;
        .text {
          font-size: 11px;
          font-weight: 700;
        }

        .row {
          border-right-style: solid;
        }
      }
      .single-line {
        margin-top: 16px;
      }
      .double-line {
        margin-top: 6px;
      }
      .buffer {
        margin: 0 12px 0 0;
      }
      .extra-info {
        color: $body-color;
      }
    }
  }

  .history-entry-info {
    margin-left: 0.5rem;
  }

  .contact-households {
    .cell {
      margin: 3px 5px;
      padding-top: 6px;
    }
    .unselected {
      color: $divider;
    }
    .disabled {
      color: $divider !important;
    }
    .modal {
      ul {
        padding: 8px 0;
        margin: 0;
      }
      label {
        padding-top: 3px;
      }
      li {
        list-style: none;
        margin: none;
      }
    }
    .household-count {
      font-weight: 500;
      color: $gray48;
    }
    .box-title {
      padding: 5px 0 10px 0;
    }
    .add-household {
      font-size: 18px;
      font-style: italic;
      color: $green;
    }
    .related-contacts {
      margin: 10px 0;
      .add-item-button {
        font-size: 13px;
        font-style: italic;

      }
    }

    @include contact-links;
    .each-house {
      text-align: center;
    }

    .household-column {
      display: table-cell;

      margin-left: 0px;
      border-bottom: 1px solid $divider;
      border-top: 1px solid $divider;
      padding-bottom: 10px;
      &:not(:first-child){
        padding-left: 10px;
        border-left: 1px solid $divider;
      }
    }
    .households {
      margin: 7px 0 20px 0;
      border-width: 1px;

      .address {
        padding: 12px 0 8px 0;
        font-size: 18px;
      }
      .household-members {
        width: 100%;
        display: table;
        padding: 0 2px;
        .box-title {
          padding: 5px 0 10px 0;
        }
        .child-box {
          width: 25%;
        }
        .parent-box {
          width: 50%;
        }
        .other-box {
          width: 25%;
        }

        ul {
          margin: 0;
          &.adults {
            li {
              float: left;
              width: 50%;
              padding-bottom: 10px;
            }
          }
          li {
            list-style: none;
          }
        }
      }
      &:hover {
        a.action {
          visibility: visible;
        }
      }
    }
    .hover-red {
      //@include transition(border linear .2s, box-shadow linear .2s);
      &.household-members {
        border-style: solid hidden solid hidden 1px $red;
        $bottom-shadow: 0px 3px 1px -1px lighten($red, 20%);
        $top-shadow: 0px -3px 1px -1px lighten($red, 20%);
        @include box-shadow($top-shadow, $bottom-shadow);

      }
      .household-column {
        &:not(:first-child) {
          border-style: hidden hidden hidden solid 1px $red;
          $shadow: -3px 0px 1px -1px lighten($red, 20%);
          @include box-shadow($shadow);
        }
      }
    }
    .hover-blue {
      //@include transition(border linear .2s, box-shadow linear .2s);
      &.household-members {
        border-style: solid hidden solid hidden 1px $blue;
        $bottom-shadow: 0px 3px 1px -1px lighten($blue, 20%);
        $top-shadow: 0px -3px 1px -1px lighten($blue, 20%);
        @include box-shadow($top-shadow, $bottom-shadow);

      }
      .household-column {
        &:not(:first-child) {
          border-style: hidden hidden hidden solid 1px $blue;
          $shadow: -3px 0px 1px -1px lighten($blue, 20%);
          @include box-shadow($shadow);
        }
      }
    }
  }

  .dupe-search-box {
    min-height: 0;
    margin-top: 5px;
    margin-bottom: 5px;

    .prepend-icon {
      float: left;
      width: 20px;
    }

    .result {
      @include rounded-corners();
      color: $body-color;
      background: $lighter-grey;
      padding: 10px;
      margin: 10px 0;

      hr {
        border: 0;
        color: $divider;
        background-color: $divider;
        height: 1px;
      }

      .row div[class^='span'], .row div[class*=' span'] {
        min-height: 0;
      }

      .info-row {
        .prepend-icon {
          color: $medium-grey;
        }
      }
    }
  }
}

#change-track-modal {
  .change-track-move, .change-track-copy {
    text-align: center;
  }

  .change-track-mode, .change-track-examples {
    display: flex;
    justify-content: space-between;
    margin: 5px 0 0 0;
  }

  .change-track-mode-choice, .change-track-example {
    width: 49%;
  }

  .change-track-mode-choice {
    cursor: pointer;
  }

  .change-track-example {
    font-style: italic;
  }

  .change-track-mode-choice {
    @include rounded-corners(5px);

    border: 1px $light-grey solid;
    padding: 15px;
    color: $medium-grey;

    &.change-track-mode-choice-active {
      color: $orange;
      border: 2px $orange solid;
    }

    .header {
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      margin-bottom: 15px;
    }

    input {
      display: none;
    }
  }

  .change-track-copy-warning {
    color: $green;
  }

  .change-track-move-warning {
    color: $red;
  }

  .change-track-target-year {
    text-align: center;
    margin: 15px 0;
  }

  .change-track-mode-verb {
    text-transform: capitalize;
  }

  legend {
    margin: 15px 0 5px 0;
  }
}

#generate-contracts-modal {
  .warning {
    @include rounded-corners(5px);
    border: 1px solid;
    padding: 10px;
    margin: 10px;

    &.blue {
      border-color: $blue;
      background-color: $blue5;
    }

    &.red {
      border-color: $red;
      background-color: $red5;
    }
  }

  .errors{
    margin-top: 10px;
  }
}

#change-role-modal {
  .helper-text {
    padding: 5px 0 0 0;
  }
  label.disabled {
    color: $medium-grey;
    margin: 0;
  }
  .disabled-note {
    color: $medium-grey;
    margin: 0 0 5px 10px;
    .advice {
      font-style: italic;
    }
  }
}

.send-auth-code.modal {
  .auth-recipient-data {
    @include make-col(12);
    font-weight: bold;
    text-align: right;
    float: left;
  }

  .auth-recipient-data-text {
    margin: 0 25px 15px 0;
  }

  .auth-email, .auth-email-value {
    margin: 15px 0;
    max-height: 18px;
  }

  .auth-recipient-data-values {
    @include make-col(12);
    float: right;
  }

  .email-input-class {
    margin-top: -5px;
  }

  .editable-buttons {
    margin-top: -7px;
  }

  .invalid-email-error {
    margin: 0 0 0 5px;
    color: red;
  }
}

.concise-contact .info-row {
  min-height: 22px;
}

.address .info-row {
  overflow: hidden;
}

.editable-phone {
  position: absolute;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 10px;
  white-space: nowrap;
}

// admin /contacts/{id} edit page
.personal-information-edit-prompts {
  .sa-form-subfield-grouping {
    .sa-form-sub-field,
    .uneditable-value,
    .sa-form-date-field {
      margin-bottom:.75rem;

      .form-text {
        display:block;
        margin-top:0px;
      }

    }
  }

  select{
    @extend .form-select;
    @extend .form-select-sm;
    width: 220px;
  }

  input[type="text"], input[type="password"],
  textarea {
    @extend .form-control;
    @extend .form-control-sm;
    width: 220px;
  }

}

.activate_account_button, .view_changes_button, .edit_contract_rule, .activate_contract_fee, .add_rule_button, .edit_contract_fee {
  color: $teal2;
  cursor: pointer;
}

.deactivate_account_button, .delete_contract_rule, .deactivate_contract_fee {
  color: $red;
  cursor: pointer;
}

.appointment_actions {
  margin-bottom: 20px;
}
