/*
  ###############################################################################################################
  Import forms pages
  ###############################################################################################################
*/

/*------------------------------------*\
  TABLE OF CONTENTS
\*------------------------------------*/
/*
 * MATCH DIALOG
 * MATCH DIALOG CONTACT BREADCRUMBS
 * MATCH DIALOG TITLE
 * MATCH DIALOG BOXES
 */

/*------------------------------------*\
  MATCH DIALOG
\*------------------------------------*/


#import-form-modal {
  top: 10px !important;

  &.modal-dialog {
    max-width:970px;
  }

  .modal-body {
    height: 580px;
    padding: 0px 15px 0px 15px;
    overflow-y: auto;
    overflow-y: auto;
  }

  .small-modal-body {
    height: 100%;
    padding: 0px 15px 0px 15px;
    overflow-y: auto;
    overflow-y: auto;
  }



  .modal-content {
    height: 100%;
  }

  .modal-body::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 11px;
    height: 11px;
  }

  .modal-body::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
  }

  .import-spinner {
    text-align: center;
    margin: 20px;
  }
}


/*------------------------------------*\
  MATCH DIALOG CONTACT BREADCRUMBS
\*------------------------------------*/
.dialog-tabs {
  margin: 0px 0px 10px 0px;
  height: 40px;

  ul {
    margin-left: 0;
    padding: 0 0 0 9px;

    li {
      list-style: none;
      height: 40px;
      float: left;
      background: url('../../images/admin_portal/import/import-bg-import-breadcrumb.gif') repeat-x 0 -160px;

      a {
        cursor: default; /* temporary treat like not link */
        float: left;
        background: url('../../images/admin_portal/import/import-bg-import-breadcrumb.gif') no-repeat 0 -160px;
        color: $gray40;
        font-size: 12px;
        line-height: 12px;
        text-decoration: none;

        small {
          font-size: 10px;
          line-height: 10px;
          display: block;
          padding: 0 0 3px 0;
        }
        span {
          display: block;
          height: 32px;
          background: url('../../images/admin_portal/import/import-bg-import-breadcrumb.gif') no-repeat right 0;
          padding: 8px 35px 0px 3px;
          margin-left: 25px;
        }

        span.single {
          height: 25px;
          padding-top: 15px;
        }

        i {
          float: left;
          margin: 11px 0px 0px 6px;
        }
      }
    }

    li.first a {
      background-position: left -240px;
      padding: 0 0 0 10px;
    }
    li.last a span {
      background-position: right -80px;
      padding: 8px 14px 0 0;
    }
    li.last a span.single {
      height: 25px;
      padding-top: 15px;
      padding-left: 2px;
    }
    li.active {
      background-position: 0 -200px;

      a {
        background-position: left -320px;
        color: $white;
        padding: 0 0 0 26px;
        position: relative;
        margin: 0 0 0 -24px;

        span {
          background-position: right -40px;
        }
      }
    }
    li.first.active a {
      background-position: left -120px; margin-left: 0;
      padding-left: 3px;

      span {
        background-position: right -40px;
      }
    }
    li.last.active a span {
      background-position: right -280px;
    }
    li a:hover {
      text-decoration: none;
    }
  }
}

/*------------------------------------*\
  MATCH DIALOG TITLE
\*------------------------------------*/
.title-form {
  color: $gray50;
  font-size: 25px;

  strong.more-space {
    padding: 5px 0 5px 0px;
  }
  strong.large-space {
    padding: 27px 0 0 13px;
  }
}

/*------------------------------------*\
  MATCH DIALOG BOXES
\*------------------------------------*/
.match-boxes {
  .tooltip {
    margin-top: 15px;

    .tooltip-inner {
      background: $blue17;
      max-width: 300px;
      font-size: 14px;
      padding: 5px;
    }
  }
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: $blue17;
}

fieldset {
  width: auto;
}

.boxes-holder {
  height:1%;

  padding: 0px 15px 0px 15px;

  .boxes {
    font-size:12px;
    line-height:15px;
    overflow:hidden;
    padding:20px 0 6px 10px;
    width:820px;
  }

  &:after {
    clear:both;
    display:block;
    content:"";
  }

  strong {
    color:$gray50;
    font-size:23px;
    line-height:25px;
    margin-bottom:8px;

    span {
      font-size:18px;
      font-weight:normal;
      padding-left:0px;
    }
  }
}

.match-boxes {
  color: $gray40;
  margin-top: 10px;
  margin-bottom: 10px;
  border-collapse: separate;
  border-spacing: 5px 0px;
  margin-left: -25px;
  margin-right: -10px;
  width: auto;

  h3 {
    margin-bottom: 5px;
  }

  // Clear padding from screen.css
  td {
    padding: 0;
  }

  // Clear margin from screen.css
  table {
    margin-bottom: 0;
    width: 100%;
  }

  // Clear margin from screen.css
  p {
    margin-bottom: 0;
  }

  .household-members {
    padding: 5px 0 5px 17px;
  }

  .match {
    background: url('../../images/admin_portal/import/import-bg-box-large.png') repeat-y -241px 0;
  }

  .match.hover {
    cursor: pointer;
    background: url('../../images/admin_portal/import/import-bg-box-large-hover.png') repeat-y -241px 0;
  }

  .match.active {
    background: url('../../images/admin_portal/import/import-bg-box-large-active.png') repeat-y -241px 0;

    .radio-holder {
      background-position: 0 0;
    }
  }

  .match {
    .content {
      width: 207px;
      max-width: 207px;
      min-width: 207px;
      overflow-x: hidden;
    }
  }

  .field-column {
    width: 150px;
  }

  .field-label {
    float: right;
    font-weight: bold;
    margin-right: 5px;
  }

  .field-value {
    vertical-align: top;

    .content {
      padding: 5px 15px 5px 15px;
      margin: 0px 2px 0px 2px;
      background: url('../../images/admin_portal/import/import-row-divider.png') no-repeat 0 0;
    }
  }

  .field-value.active {
    .content {
      background: url('../../images/admin_portal/import/import-row-divider-active.png') no-repeat 0 0;
    }
  }

  h3 {
    font-size: 16px;
    color: $gray51;
    display: inline;
    font-weight: bold;
    text-align: left;
  }

  .box-top {
    width: 241px;
    background: url('../../images/admin_portal/import/import-bg-box-large.png') no-repeat 0 0;
    padding-top: 8px;
  }

  .box-top.hover {
    background: url('../../images/admin_portal/import/import-bg-box-large-hover.png') no-repeat 0 0;
    padding-top: 8px;
  }

  .box-top.active {
    background: url('../../images/admin_portal/import/import-bg-box-large-active.png') no-repeat 0 0;
    padding-top: 8px;
  }

  .box-bottom {
    padding-top: 8px;
    background: url('../../images/admin_portal/import/import-bg-box-large.png') no-repeat -482px bottom;
  }

  .box-bottom.hover {
    background: url('../../images/admin_portal/import/import-bg-box-large-hover.png') no-repeat -482px bottom;
  }

  .box-bottom.active {
    background: url('../../images/admin_portal/import/import-bg-box-large-active.png') no-repeat -482px bottom;
  }

  .match-image {
    padding-bottom: 5px;
  }

  .radio {
    text-align: center;
    padding: 15px 0px 0px 0px;

    .radio-holder {
      display: block;
      width: 40px;
      height: 40px;
      margin: 0 auto;
      cursor: pointer;
      background: url('../../images/admin_portal/import/import-bg-radio-button.png') no-repeat -40px 0;
    }

    .field-radio {
      display: none;
    }
    p {
      font-size: 14px;
      line-height: 16px;
      min-height: 17px;
      margin: 0;
    }
  }

  .item-view {
    width: 100%;
    position: relative;
    min-height: 43px;
    padding: 25px 0 0 0;

    img {
      position: absolute;
      left: 5px;
      top: 10px;
    }

    p {
      padding: 0 5px 0 65px;
    }

  }

  .heading-view {
    font-size: 13px;
    font-weight: bold;
    text-align: center;
  }

  .import-contact-icon {
    text-align: center;
  }
}
