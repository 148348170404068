.authentication {
  width: 790px;
  margin-left: auto;
  margin-right: auto;

  h2 {
    color: $white;
    margin: 0 0 10px 0;
  }

  .login-box {
    border-bottom: 3px solid $blue16;
    color: $white;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 20px;


    &.narrow {
      background: url('../../images/shared_components/login-bg-narrow.png') no-repeat scroll center top transparent;
      width: 350px;
    }

    &.wide {
      background: url('../../images/shared_components/login-bg-wide.png') no-repeat scroll center top transparent;
      width: 750px;
    }

    a {
      color: $white;
    }

    h1, h2, h3, h4, h5, h6 {
      color: $white;
    }

    .maintenance-warning {
      margin: -10px -20px 10px -20px;
    }

    .form-horizontal {
      .mb-3 {
        &:last-child {
          margin: 0;
        }

        .col-form-label {
          width: 100px;
        }

        .controls {
          margin-left: 120px;

        }

        .checkbox {
          padding-left: 15px;
        }

        .checkbox input[type="checkbox"] {
          margin-left: -15px;
        }
      }
    }
  }
}
