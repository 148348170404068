.crud-header{
  .crud-options-support-link {
    float:right;
  }
}

.crud-header-top {
  display: flex;
  justify-content: space-between;
}
