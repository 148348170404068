.sa-form {
  .payment-item {
    margin-bottom: 6px;
    overflow: hidden;

    .payment-item-body {
      width: 460px;
      float: left;

      @media print {
        width: 320px;
      }

      label.checkbox,
      label.radio {
        margin-left: 1px;
      }
    }

    .form-fee-name {
      color: $body-color;
    }

    .form-fee-description {
      margin-top: 3px;
      font-weight: normal;
      color: $medium-grey;
    }

    .payment-item-fee {
      font-weight: bold;
      width: 100px;
      margin-left: 480px;

      @media print {
        margin-left: 340px;
      }
    }

    .payment-item-fee,
    .form-fee-total {
      text-align: right;

      &.negative-fee {
        color: $negative-fee-color;
      }
    }

    .form-fee-total {
      border-top: 1px solid $total-line-color;
      font-size: 16.25px;
      padding-top: 4px;
    }

    &.selected {
      .form-fee-name {
        color: $black;
        font-weight: bold;
      }
    }
  }

  .payment-info {
    margin: 50px 0 0;
  }

  .payment-methods {
    width: 580px;
  }
}
