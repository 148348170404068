#school-years-list {
  .default-for-reporting {
    margin-left: 0;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .fa.fa-edit-sign{
    font-size: 22px;
    text-decoration: none;
  }
}

