.table-horizontal {
  td {
    &:first-child {
      min-width: 180px;
      max-width: 180px;
      text-align: right;
      font-weight: bold;
    }

    padding: 4px 6px;
    vertical-align: top;
  }
}

//overwrite BS5 upgrade to border-bottom-color back to what it originally was
.table > :not(:last-child) > :last-child > *, 
.contact-list-react > :not(:last-child) > :last-child > * {
  border-bottom-color:inherit !important;
}