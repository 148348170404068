@import './shared/helpers/bootstrap_loader';
@import './admin_portal/helpers/fluid_columns';

@import './helpers/globals';

@import './shared/base/base';

@import './admin_portal/modules/admin_forms';
@import './admin_portal/modules/audit_history_viewer';
@import './admin_portal/modules/contact_list';
@import './admin_portal/modules/pagination';
@import './admin_portal/modules/review';
@import './admin_portal/modules/teacher_rec_admin';
@import './admin_portal/modules/triangle_breadcrumb';

@import './layout/admin_portal';
@import './layout/header';
@import './layout/internal';
@import './layout/modal';
@import './layout/page_layout';
@import './layout/setup';


@import './shared/modules/buttons';

@import './components/action_buttons';
@import './components/associated_accounts';
@import './components/authentication';
@import './components/body_spinner';
@import './components/calendars';
@import './components/checkbox_list';
@import './components/checklist';
@import './components/chosen';
@import './components/ck_fu';
@import './components/colored_tooltips';
@import './components/contact';
@import './components/contact_filters';
@import './components/contact_header';
@import './components/contact_list';
@import './components/contact_nav';
@import './components/contextual_nav';
@import './components/contract_fee_list';
@import './components/control_group';
@import './components/data_import';
@import './components/dropdown_menus';
@import './components/dupe_search';
@import './components/fee_management';
@import './components/filters';
@import './components/flat_pill';
@import './components/form';
@import './components/form_import';
@import './components/form_list';
@import './components/form_templates';
@import './components/froala_customizations';
@import './components/funnel';
@import './components/generate_letters';
@import './components/icons';
@import './components/list';
@import './components/list_divider';
@import './components/lock_control';
@import './components/lottery_presentation';
@import './components/new_household';
@import './components/notes';
@import './components/payment';
@import './components/reports';
@import './components/sa_form_fields';
@import './components/schedule_list';
@import './components/search';
@import './components/show_email';
@import './components/table';
@import './components/track-silo-control';
@import './components/update_fields';
@import './components/widget';

@import './pages/setup/base';
@import './pages/setup/crud_header_pages';
@import './pages/setup/exports_details';
@import './pages/setup/field_customization';
@import './pages/setup/manage_lotteries';
@import './pages/setup/mms_setup';
@import './pages/setup/parent_portal_content';
@import './pages/setup/school_years';
@import './pages/modals/update_fields_form';
@import './pages/modals/view_invite';
@import './pages/add_contact_modal';
@import './pages/contact';
@import './pages/dashboard';
@import './pages/error';
@import './pages/run_lottery_modal';


// Load third party assets
@import '~fullcalendar/dist/fullcalendar.css';
@import '~bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css';
@import '~chosen-js/chosen.css';
@import '~bootstrap-timepicker/css/bootstrap-timepicker.css';
@import '~jquery-jcrop/css/jquery.Jcrop.css';
@import 'Vendor/javascripts/dynatree-1.2.4/skin/ui.dynatree.css';
@import 'Vendor/javascripts/jquery-autocomplete-1.2.3/jquery.autocomplete.css';
@import 'Vendor/javascripts/bootstrap-datetimepicker-2.2.0/css/bootstrap-datetimepicker.min.css';
@import 'Vendor/javascripts/palette-1.0/palette.css';
@import '~froala-editor/css/froala_editor.min.css';
@import '~froala-editor/css/froala_style.min.css';
@import '~froala-editor/css/plugins/code_view.min.css';
@import '~froala-editor/css/plugins/colors.min.css';
@import '~froala-editor/css/plugins/draggable.min.css';
@import '~froala-editor/css/plugins/emoticons.min.css';
@import '~froala-editor/css/plugins/fullscreen.min.css';
@import '~froala-editor/css/plugins/table.min.css';
@import '~froala-editor/css/plugins/video.min.css';
@import '~froala-editor/css/plugins/image.min.css';
@import '~froala-editor/css/plugins/image_manager.min.css';
@import '~blueimp-file-upload/css/jquery.fileupload-ui';
@import '~blueimp-file-upload/css/jquery.fileupload';
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker';

body,
html {
  -webkit-font-smoothing: antialiased;
}

form {
  margin: 0;
}

.pagination {
  margin: 0;
}

.table {

  th,
  td {
    vertical-align: middle;
  }
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
}

.no-select-all {
  & *:not(input) {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently */
  }
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -12px 0 0 -12px;
}

input[class^='span'] {
  min-height: inherit;
}

//override for xeditable's old font awesome icons
.icon-arrow-right {
  @extend .fa;
  @extend .fa-chevron-right;
}

.icon-arrow-left {
  @extend .fa;
  @extend .fa-chevron-left;
}

// override for bootstrap 2 -> 3 default box-sizing change
.reset-box-sizing,
.reset-box-sizing *,
.reset-box-sizing *:before,
.reset-box-sizing *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

// Hides the pendo notification button

.pendo-resource-center-badge-notification-bubble {
  display: none;
}
