@import '../shared/helpers/bootstrap_variables';

a.search-clear {
  color: $red;
  margin-left: 15px;
}

.subview-data-spinner {
  margin: 5px 0 0 10px;
}

.criteria-main {
  margin: 10px 0 0 0;
  clear: right;
}

.search-box {
  @include transition(border linear .2s, box-shadow linear .2s);
  padding: 0px;
  margin-bottom: 0px !important;

  &.remove-highlighted {
    /* When remove button is highlighting, the box gets this class */
    border-color: $red;
    $shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px lighten(red, 20%);
    @include box-shadow($shadow);
    @include transition(border linear .2s, box-shadow linear .2s);
  }

  &.criteria-advanced {
    margin: 0 0 25px 0;

    .accordian-inner {
      padding-top: 25px;
    }
  }

  .sentence-header {
    display: inline-block;
    font-size: 13px;
    font-style: italic;
    margin: 0px 15px;
    color: $medium-grey;
    padding-top: 4px;

    .conjunction {
      font-weight: bold;
      font-style: normal;
    }
  }

  hr {
    margin: 0 15px;
  }

  .mb-3.number {
    margin: 0px;
  }

  .search-box-main {
    padding: 10px 0px 10px 0px;
    margin: 0px 15px 0px 15px;
    width: auto;

    .controls-row {
      margin: 10px 0 0 0;

      input {
        margin-bottom: 0px;
      }

      &.row [class*="col-"] {
        padding-left: 0;
      }
    }

    &>.controls-row,
    .controls-row.first-row {
      margin: 0px;
    }

    .conjunction {
      text-align: center;
      line-height: 30px;
      margin-left: 5px;
    }

    .date-input {
      display: inline;
      margin-bottom: 0px;
      height: 30px;

      input {
        height: 30px;
        margin-right: 0px;
        width: calc(100% - 16px);
      }

      span+input {
        height: 30px;
        top: -1.75em;
        display: inline;
        position: relative;
        float: right;
        margin-right: 6px;

        .fa.fa-calendar {
          color: $teal;
          z-index: 9;
        }
      }
    }

    .search-box-detail {
      .col-form-label {
        text-align: right;
        line-height: 32px;
        padding-top: 0px;
      }
    }
  }
}

.track-silo {
  text-align: center;

  .controls {
    text-align: center;
    margin-top: 10px;
  }
}

.results-track-silo {
  display: inline-block;

  i {
    color: $black;
  }
}


.term-info-modal {
  .text-block {
    padding: 0 0 10px 0;
  }

  .text-block-bold {
    font-weight: bold;
  }
}

.search-builder {
  margin: 0 0 25px 0;

  h2 {
    .subtitle {
      color: $medium-grey;
      font-size: 18px;
    }
  }

  .results-count {
    display: inline;
  }

  .term-selector-info {
    margin-top: -3px;

    a:hover {
      text-decoration: none;
    }
  }

  .term-selector-detail {
    font-size: 18px;

    a {
      font-size: 13px;
    }

    a:hover {
      text-decoration: none;
    }
  }

  .criteria-compact {
    display: inline;
    vertical-align: middle;
    line-height: 26px;

    .terms {
      display: inline;

      .descriptions {
        .label-wrapper {
          &:after {
            content: " and ";
          }

          &.or:after {
            content: " or ";
          }

          &:last-child:after {
            content: none;
          }
        }
      }
    }

    .search-clear {
      margin-left: 3px;
    }
  }

  .criteria-description {
    margin-bottom: 15px;

    .terms {
      font-size: 16px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      padding: 10px;

      .description-wrapper {
        &:after {
          content: " and also ";
        }

        &.or:after {
          content: ", or ";
          font-weight: bold;
        }

        &:last-child:after {
          content: none;
        }
      }
    }
  }

  .button-container {
    margin: 5px 0 0 0;
  }

  input[type="text"] {
    height: 30px;
  }

  .toggle-search-terms {
    @include page-header-links;
    margin-bottom: 0;
  }

  /* Chosen styling */
  .chosen-choices,
  .chosen-results {
    border-radius: 4px;
  }

  .chosen-results {
    border-radius: 0 4px 0 4px;
  }

  .chosen-results .highlighted {
    background-color: $blue;
    background-image: none;
  }

  .chosen-container-single .chosen-single {
    height: 28px;
    line-height: 29px;
  }

  .chosen-container-single .chosen-single abbr {
    top: 7px;
    width: 14px;
    height: 14px;
    background-position: -42px 4px;
  }

  .chosen-container-single .chosen-single div b {
    background-position: 0px 4px;
  }

  /* [tch] chosen single select search bar magnifying glass icon is slightly
   * misaligned.  This rule would align it, but since all the icons are on the
   * same sprite image, partial icons are shown when the sprite is aligned in
   * this way.  Living with minor alignment issue for now... a clever CSS way
   * to crop this image would solve the issue...
  .chosen-container-single .chosen-search input {
    background-position: 100% -18px;
  }
  */

  .chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position: -18px 4px;
  }

  .chosen-container-multi .chosen-choices .search-field {
    height: 28px;

    input {
      height: 16px;
    }
  }

  .chosen-container {
    display: block;
  }

  .chosen-container .chosen-choices .search-field:only-child input {
    width: 100% !important;
  }

  .criteria-standard {
    .row {

      &::before,
      &::after {
        content: none;
      }
    }

    .add-criterion-footer {
      margin-bottom: 15px;

      .spacer-container {
        width: 100%;

        .plus-container {
          color: $green;
          font-size: 16px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .add-criterion-section-wrapper {
          padding: 0;
        }

        .spacer-box {
          @include transition(border linear .2s, box-shadow linear .2s);

          &.plus-highlighted {
            border-color: $green;
            $shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px lighten($green, 20%);
            @include box-shadow($shadow);
            @include transition(border linear .2s, box-shadow linear .2s);
          }

          margin: 0;
          padding: 10px 15px 10px 15px;
          width: auto;
        }
      }
    }

    .search-box-detail {
      width: 100%;
    }

    .subview {
      margin: 0px;
      padding: 0px;
    }

    ul {
      list-style: none;
      margin: 0 0 0 0;
    }

    .criterion {
      &>div {
        width: 100%;

        .crit-list-controls {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;

          i {
            font-size: 16px;
          }

          .fa.fa-minus-circle {
            color: $red;
          }

          .fa.fa-plus-circle {
            color: $green;
          }
        }

        .connective {
          position: relative;
          padding: 0 1px;
          transition: background-color 0.25s ease;

          &.connected {
            background-color: $gray75;
            border-right: 1px solid $gray7;
            border-left: 1px solid $gray7;
            z-index: 1;
            padding: 5px 0;
            margin-top: -5px;
            margin-bottom: -5px;
            transition: background-color 0.25s ease;
          }

          &.single {
            visibility: hidden;
          }

          .spacer-box {
            padding: 10px 0 10px 28px;
          }
        }
      }
    }

    .chosen-italic {

      /* This stanza attempts to make just the top selection italicized */
      a.chosen-default span {
        font-style: italic;
      }
    }

    .chosen-container {
      vertical-align: top;
      font-size: 13px;
      float: none;

      .separator-below {
        border-bottom: 1px solid $light-brown;
      }

      a.chosen-default span,
      input.default {
        color: $medium-grey;
      }

      .indent {
        text-indent: 10px;
      }

      .group-result {
        text-transform: capitalize;
      }

      &.all-of {
        .search-choice+.search-field:before {
          content: "and...";
        }
      }

      .search-choice+.search-field:before {
        content: "or...";
        line-height: 30px;
        padding: 0 0 0 4px;
        color: $medium-grey;
        font-style: italic;
      }
    }
  }
}

.modal-body {
  .dynatree-container {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
}

.dynatree-container {
  margin: 0;
  padding: 0px;
}

#cancel-button {
  position: relative;
  left: 183px;
}

.dynatree-container {
  width: 520px;
  height: 172px
}

.search-controls {
  text-align: right;

  &.bottom-controls {
    margin: 15px 4px 0 0;
  }

  .pagination {
    margin: 0 0 0 10px;
  }
}

.change-display {
  margin: 5px 0 0 0;
  text-align: right;

  .triangles {
    display: inline-block;
    border-top: 13px solid $teal2;
    border-bottom: 13px solid $teal2;
    border-right: 13px solid $teal2;
    border-left: 13px solid transparent;
    width: 0px;
    height: 0px;
    margin-right: -4px;
    vertical-align: top;
  }

  a {
    display: inline-block;
    height: 26px;
    line-height: 29px;
    background-color: $teal2;
    color: white;
    padding-right: 15px;
  }
}

.search-content {

  .no-results {
    color: $black;
    display: inline-block;
    width: 100%;
    font-size: 18px;
    line-height: 60px;
    margin: 0px 0 25px 0;
    text-align: center;
  }

  .scrollable {
    overflow: visible;
    width: 100%;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }

  .contact-list {
    border: none;
    margin: 0;
    border-collapse: separate;
    /* Don't collapse */
    border-spacing: 0;

    td:nth-child(2) {
      position: sticky;
      left: 72px;
      background: white;
      z-index: 1;
    }

    th:nth-child(2) {
      position: sticky;
      left: 72px;
      background: white;
      border-left: 1px solid $gray5;
      z-index: 2;
    }

    z-index: 3;

    .search-table-header-groupings {
      height: 15px;
      border-right: none;

      th.contact {
        position: sticky;
        z-index: 1;
        background-color: $white;
        border-bottom: 1px solid $gray12;
      }

      th {
        color: $medium-grey;
        padding: 0 8px 0 8px;
        background-color: $gray64;
        @include rounded-top-corners(8px);
        border-bottom: 1px solid $gray7;
        text-transform: uppercase;
        font-size: 11px;
        line-height: 11px;

        &:first-child {
          /* Blank out corner cell */
          background-color: $white;
        }

        &.contact {
          /* Contact group heading gets different bg shade */
          background-color: $gray7;

          &:nth-child(2) {
            position: sticky;
            z-index: 3;
          }

          &.gap::before {
            content: "";
            position: absolute;

            background-color: $gray7;
            bottom: 10px;
            height: 5px;
            width: 5px;
            right: 5px;
            border-top-right-radius: 6px;
            box-shadow: 2px -2px 0 2px $white;
          }

          z-index: 4;
        }

        z-index: 5;

        &.gap {
          border-left: none;
          border-right: none;
          background-color: $white;
          z-index: 5;
        }

        &.gap::before {
          content: "";
          position: absolute;
          background-color: transparent;
          bottom: 10px;
          height: 5px;
          width: 5px;
          right: 5px;
          border-top-right-radius: 6px;
          box-shadow: 2px -2px 0 2px $white;
        }

        &.gap::after {
          content: "";
          position: absolute;
          background-color: $white;
          bottom: 10px;
          height: 5px;
          width: 6px;
          right: -5px;
          border-top-left-radius: 0;
          box-shadow: 2px 2px 2px 2px $white;
        }
      }
    }

    td {
      border-bottom: 1px solid $gray65;
    }

    .checkbox-column {
      width: 30px;
    }

    .checkbox-cell {
      border-right: 1px dotted $gray7;
      position: sticky;
      left: 0;
      background: white;
      z-index: 2;
      line-height: 35px;
    }

    th.checkbox-cell:nth-of-type(1) {
      z-index: 3;
    }

    th {
      position: sticky;
      top: 0;
    }


    td.gap,
    th.gap {
      border-left: 1px dotted $gray7;
      border-right: 1px dotted $gray7;
      border-top: 0;
      border-bottom: 0;
      padding: 0;
      max-width: 5px;
      min-width: 5px;
      width: 5px;
    }

    colgroup.gap {
      max-width: 5px;
      min-width: 5px;
      width: 5px;
    }

    .gap:last-child {
      border-right: none;
      border-top: none;
      border-bottom: none;
      width: 0px;
      min-width: 0px;
      max-width: 0px;
    }

    td.grouping-row {
      background-color: $gray64;
      font-weight: bold;
    }
  }

  .summary-report {
    .group-name {
      background-color: $gray7;
      font-weight: bold;
    }

    .group-value {
      background-color: $gray64;
      font-weight: bold;
    }

    &.unidimensional {
      .group-name:first-child {
        border-top-left-radius: 4px;
      }

      .group-name:last-child {
        border-top-right-radius: 4px;
      }
    }

    &.bidimensional {

      /* Make pretty using radii */
      thead tr:first-child .group-name:last-child {
        @include rounded-top-corners(4px);
      }

      thead tr:last-child .group-name:first-child {
        /* Top left second row header cell (below blank cell) */
        border-top-left-radius: 4px;
        border-top: none;
      }

      tbody tr:last-child .group-value:first-child {
        /* Bottom left most cell */
        border-bottom-left-radius: 4px;
      }
    }
  }
}

#graph {
  width: 1200px;
  height: 800px;
  margin-right: auto;
  margin-left: auto;

  .jqplot-yaxis {
    color: $gray66;
    font-family: Arial, sans-serif;
  }

  .jqplot-point-label {
    font-family: Arial, sans-serif;
  }

  .jqplot-yaxis-label {
    left: -35px !important;
    padding: 10px 0 0 10px;
  }

  .jqplot-xaxis-label {
    top: 5px;
  }
}
