@import '../shared/helpers/bootstrap_variables';

.bootstrap-select {
  color: $gray13;
  line-height: 32px;
  margin-top: -8px;
  border: 2px solid $gray13;
  border-radius: 5px;
  min-width:145px;


  .filter-option {
    font-weight: normal;
    font-size: 16px;
  }

  &.dropup span.caret {
    border-bottom-color: $gray7;
  }

  span.caret {
    border-top-color: $gray7;
  }
  button.btn {
    /* Kill ugly yellow highlighting for button */
    background-color: transparent !important;
    color:$black;
    &:focus {
      outline: none !important;
    }
  }

  .dropdown-menu.show {
    ul.dropdown-menu{
      display:block;
    }
  }
}

.track-silo {
  display: inline;
}

select.track-silo-control {
  @extend .form-select;
  display: inline-block;
  width: auto;
}



