.fr-view table.fr-no-borders td,
.fr-view table.fr-no-borders th {
  border: 0;
}

.fr-view hr {
  page-break-after: auto;
}

.fr-popup.fr-active {
  z-index: 1075 !important;
}

.fr-popup .fr-input-line input[type=text] {
  height: auto;
  padding: 12px 0 2px;
}

.fr-popup .fr-checkbox-line label {
  display: inline-block;
}
