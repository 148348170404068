@import '../shared/helpers/bootstrap_variables';

.control-group {
  @include clearfix();
  margin-bottom: 15px;

  .control-label {
    @extend .col-form-label;
    float: left;
    width: 160px;
    text-align: end;
  }

  .controls {
    margin-left: 180px;

    input[type=checkbox] {
      margin-top: calc(0.375rem + 1px);
      margin-bottom: calc(0.375rem + 1px);
      font-size: inherit;
      line-height: 1.5;
    }
  }
}
