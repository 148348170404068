@import '../../shared/helpers/bootstrap_variables';

.review-setup-warning {
  margin: 0 0 25px 0;
  font-weight: bold;
}

.review-contact-list [class*="review-field-_review_rating"] {
  background: $gray5;
}

.review-contact-list [class*="review-field-_review_rating"],
.review-contact-list [class*="review-field-_review_progress"] {
  &:hover {
    cursor: pointer;
  }
}

.review-contact-list [class*="review-field-_review_rating"],
.review-contact-list [class*="review-field-_review_combined_average"] {
  border-right: 1px solid $gray3;
  border-left: 1px solid $gray3;
  text-align: center;
}

.review-contact-list td {
  border-bottom: 1px solid $gray3;
}

.review-contact-list [class*="review-header-_review_rating"],
.review-contact-list [class*="review-field-_review_progress"] {
  text-align: center;
}

.review-contact-list-header-row {
  @include make-row();
}

.review-contact-list-header-container,
.review-contact-list-action-buttons {
  @include make-col-ready();
  @include make-col(12);
}

.review-contact-list-header {
  display: inline-block;
  margin-right: 8px;
}

.review-contact-list-toggle-comments:hover {
  cursor: pointer;
}

.review-field-display_name {
  white-space: nowrap;
}

.review-status-popover-header {
  font-weight: bold;

  &:not(:first-child) {
    margin: 15px 0 0 0;
  }
}

.review-scores-popover-reader {
  font-weight: bold;
}

.review-contact-list .review-no-readers td {
  border-top: 1px solid $yellow1;
  border-bottom: 1px solid $yellow1;
  background: $yellow4;
  border-left-color: $yellow1;
  border-right-color: $yellow1;

  &[class*="review-field-_review_rating"],
  &[class*="review-field-_review_combined_average"] {
    background: $yellow3;
  }

  &[class*='review-field-_review_combined_average'] {
    background: $yellow2;
  }
}

.review-contact-list [class*='review-field-_review_combined_average'] {
  background: $gray4;
}

.review-readers-complete {
  font-size: 18px;
  color: $green1;
}

.review-contact-list .review-view-column {
  min-width: 0;
  max-width: 60px;
}

.review-comment-field {
  font-weight: bold;
}
