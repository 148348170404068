@import 'app/assets/stylesheets/helpers/globals';

// Styles specific to the contact record

#container .contact {
  // Two column layout
  .column {
    width: 48%;

    &:first-child {
      float: left;
    }

    &:last-child {
      float: right;
    }
  }

  .uneditable-value {
    margin-top: 5px;
  }

  .enrollment-type-edit {
    margin-top: 5px;
    font-weight: normal;
  }

  .enrollment-type-edit-bold {
    font-weight: bold;
  }

  .waitlist-position {
    font-weight: normal;
    font-style: italic;
    margin-top: 5px;
  }

  .sa-form-textarea {
    white-space: pre-wrap;
  }
}
