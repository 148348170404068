@import '../../javascripts/react/constants/variables.scss';

.tooltip.white .tooltip-inner {
  background: $white;
  color: $black;
  text-shadow: none;
}

.tooltip.white .tooltip-arrow {
  border-bottom-color: $white;
}

.tooltip.white.in {
  opacity: 1;
  filter: alpha(opacity=100);
}