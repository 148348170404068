@import '../../javascripts/react/constants/variables.scss';


html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.right {
  float: right;
}

.left {
  float: left;
}

.center {
  float: none !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.inline {
  display: inline;
}
.new-file-base-name {
  width: 250px;
}
.delete-download-edit {
  width: 180px;
  .edit-file-name-link,
  .delete-file-link {
    padding-right: 2px;
    &::after {
      content: " | ";
      color: black;
    }
  }
}

.inline-block {
  display: inline-block;
}

.bold {
  font-weight: bold !important;
}

.italic {
  font-style: italic !important;
}

.cl {
  clear: both;
}

.hidden {
  display: none;
}

.teal {
  color: $teal !important;
}

.orange {
  color: $orange !important;
}

.black {
  color: $black !important;
}

.blue {
  color: $blue !important;
}

.green {
  color: $green !important;
}

.red {
  color: $red !important;
}

.medium-grey {
  color: $medium-grey !important;
}

.error {
  color: $red16;
}

.relative {
  position: relative;
}

.prepend-icon {
  float: left;
  width: 20px;
  img {
    margin: 0 0 3px 0;
  }
}

.append-icon {
  width: 20px;
  img {
    margin: 0 0 3px -1px;
  }
}

.top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.bottom {
    position: absolute;
    bottom: 0;
}

.bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.top-left {
  position: absolute;
  top: 0;
  left: 0;
}

.bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}

label.required:after {
  content: " *";
  color: $red;
}

.required-before:before {
  content: "* ";
  color: $red;
}

.no-margin {
  margin: 0 !important;
}

.margin-top-small {
  margin-top: 1em;
}

.margin-top {
  margin-top: 1.5em;
}

.margin-bottom {
  margin-bottom: 1.5em;
}

.margin-vertical {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.vert-bottom {
  vertical-align: text-bottom;
}

.text-center {
  text-align: center !important;
}

.table {
  &.table-align-top {
    td {
      vertical-align: top;
    }
  }
}

.table-cell {
  display: table-cell;
}

.table-row {
  display: table-row;
}


.popover-content {
  min-height: 35px;

  .spinner {
    margin: 10px 0 0 0;
  }
}

.fa-fw {
  width: 1.28571429em;
  display: inline-block;
  text-align: center;
}

.checkbox-text,
.radio-text {
  position: relative;
  top: 3px;
}


.hint {
  color: $medium-grey;
}

.inline-label {
  text-align: right;
  padding: 6px 0 0 0;
}

.inline-radio {
  display: inline-block;
  margin-left: 15px;

  label.radio {
    margin: 0;
    padding: 0;
    line-height: 16px;
  }
  input[type="radio"] {
    margin: 0 5px 0;
    padding: 0;
  }
}

.modal h1 {
  color: $gray69;
  font-size: 24px;
  font-weight: bold;
}

.list-unstyled {
  ul {
    list-style: none;
  }
}

.range-conjunction {
  line-height: 2.2em;
}

.helper-text {
  font-style: italic;
  color: $medium-grey;
  margin: 0 0 10px 0;
}

/*------------------------------------*\
  Notifications
\*------------------------------------*/

.notifications {
  position: fixed;
  top: 30px;
  width: 500px;
  left: 50%;
  margin-left: -250px;
  text-align: center;
  z-index: 1000;

  .alert {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    display: table;
    width: 500px;

    a {
      text-decoration: none;
    }
  }
}

//gets rid of the text-clearing X for all IE text fields
input::-ms-clear { display: none; }

.maintenance-warning {
  text-align: center;
  background: $divider;
  color: $body-color;
  line-height: 25px;

  .fa.fa-stack {
    i.fa.fa-circle {
      color: $orange;
      font-size: 1.5em;
    }

    i.fa.fa-wrench {
      color: $white;
      font-size: 1em;
    }
  }
}

.session-timeout-warning {
  text-align: center;
  background: $red0;
  color: $white;

  .session-timeout-warning-header {
    font-size: 20pt;
    margin-bottom: 10px;
  }

  a {
    color: $white;
  }

  padding: 15px;
}

.datepicker, .bootstrap-timepicker-widget {
  z-index: 9999;
}

%list-unstyled {
  list-style: none;
}

%fa-fw {
  width: 1.28571429em;
  display: inline-block;
  text-align: center;
}

%helper-text {
  font-style: italic;
  color: $medium-grey;
  margin: 0 0 10px 0;
}

.statement-disclaimer {
  font-size: 0.875em;
  margin-top: 8px;
  margin-bottom: 0px;
}
