#generate-letters-modal {

  .preview-header {
    padding: 0 10px;
  }

  .snippets-expandable {
    padding: 10px;
  }

  .snippet-expandable {
    text-decoration: none;
  }

  .new_snippet {
    clear: both;
  }

  #preview-note {
    font-style: italic;
    padding-left: 12px;
  }

  .snippet-container {
    margin-bottom: 20px;
  }

  .snippet-toggle {
    background: $gray36;
    border: 1px solid $gray14;
    padding: 5px;
    border-style: solid none;
  }

  .snippet-toggle-target {
    background-color: $gray61;
    padding: 20px;
  }

  .snippet-preview {
    border-bottom: 1px solid $gray14;
  }

  .snippet-page {
    line-height: 1.5;
    background-color: $white;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 6px rgba(50, 50, 50, 0.55);
    -moz-box-shadow: 0px 0px 6px rgba(50, 50, 50, 0.55);
    box-shadow: 0px 0px 6px rgba(50, 50, 50, 0.55);
  }
}