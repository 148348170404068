.dupe-search-box {
  min-height: 0;
  margin-top: 5px;
  margin-bottom: 5px;

  .result {
    @include rounded-corners();
    color: $black;
    background: $lighter-grey;
    padding: 10px;
    margin: 10px 0;

    .info-row {
      .prepend-icon {
        color: $medium-grey;
        margin-bottom: 10px;
        &.hover {
          text-decoration: none;
        }
      }
    }

    .name {
      color: $medium-grey;
    }
  }
}