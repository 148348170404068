@import "../shared/helpers/bootstrap_variables";

.dropdown-menu {
  font-size: 13px;
}

.dropdown-title-link {
  color: $grey0;
  cursor: pointer;
}

.dropdown-item {
  cursor: pointer;

  &.form-link {
    color: $teal;
  }

  &:active {
    background: $primary;
  }
}

.dropdown-header {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  padding-top: 5px;
  padding-bottom: 5px;
}

.dropdown-selected {
  font-weight: bold;

  > .dropdown-item {
    font-weight: bold;
    color: $primary;
  }
}

.dropdown-toggle:after {
  font: normal normal normal 10px/1 FontAwesome;
  border-top: none;
  content: "\f078";
}

.dropdown-display:after {
  display: inline-block;
}

.dropdown-menu li {
  position: relative;
}
.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}
.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}
.dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
}

.dropdown-menu > li > a.dropdown-toggle:before {
  color: $gray3;
  font: normal normal normal 10px/1 FontAwesome;
  content: "\f053";
  padding: 0 2px 0 0;
  margin-left: -12px;
}

.dropdown-menu > li > a.dropdown-toggle:after {
  display: none;
}
