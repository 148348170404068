@import '../shared/helpers/bootstrap_variables';

#container .widget {
  margin: 0 0 50px 0;

  .header {
    h2 {
      font-size: 21px;
      font-weight: 500;
      line-height: 21px;
      display: inline;
      color: $body-color;
    }
    .helper-text {
      margin-top: -5px;
    }
    &.household {
      margin-top: 18px;
    }
    // Header line height of 21px results in 25px divs
    line-height: 25px;
  }
  .contact-details {
    margin: 15px 0 0 0;
  }

  .empty {
    color: $medium-grey;
    padding: 10px;
    margin: 0;
  }

  .add-item-button.fa.fa-plus-circle {
    font-size: 18px;
  }
}
