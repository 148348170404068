@mixin page-header-links {
  padding: 8px 0 8px 0;
  font-size: 13px;
  color: $light-blue;
  a {
    font-weight: bold;
  }
  /*margin-left: 15px;*/
  /*line-height: 40px;*/
  margin-bottom: 10px;
}

.page-header-links {
  @include page-header-links
}