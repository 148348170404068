.fee-group {
  margin: 25px 0 0;
}

.fee-group .fee-group-edit,
.fee-group .fee-group-delete,
.fee-details .fee-edit,
.fee-details .fee-delete,
.fee-rule .fee-rule-edit,
.fee-rule .fee-rule-delete {
  visibility: hidden;
}

.fee-group:hover .fee-group-edit,
.fee-group:hover .fee-group-delete,
.fee-details:hover .fee-edit,
.fee-details:hover .fee-delete,
.fee-rule:hover .fee-rule-edit,
.fee-rule:hover .fee-rule-delete {
  visibility: visible;

}

.fee-management-actions {
  margin: 0 0 0 10px;
  display: inline-block;
  vertical-align: top;

  .btn + .btn {
    margin: 0 0 0 3px;
  }
}

.fee-groups-empty {
  margin: 20px 0 0;
}

.fee-group-name {
  font-size: 21px;
  font-weight: bold;
  margin: 0 0 8px;
  display: inline-block;
}

.fee-rules {
  background: $lighter-grey;

  > td {
    padding: 15px 25px 0 25px;
  }
}

.fee-rules-empty {
  color: $red;

  &:hover {
    color: $dark-red;
    text-decoration: underline;
  }

  &:focus {
    text-decoration: none;
    color: $red;
  }
}

.fee-listing-table {
  margin: 10px 0 0;
}

.admin #container .fee-listing-table .add-item-button {
  padding: 0;
}

.fee-rules-table {
  margin: 10px 0 0;
  background-color: transparent !important;
  border-collapse: separate;

  th {
    border: 0;
  }

  td {
    border-top: 1px dashed $light-grey;
  }
}

.fee-rules .ui-sortable-helper {
  background: $gray49;
}

.fee-rules .ui-sortable-helper td {
  border-bottom: 1px dashed $light-grey;
}

.fee-management-instructions {
  font-style: italic;
  margin: 0 0 5px;
  color: $black;

  ul {
    @extend %list-unstyled;
    margin: 5px 0 0 10px;
  }

  li {
    line-height: 22px;
  }

  i {
    @extend %fa-fw;
  }
}

// Gotta get a little verbose here to override the shared style
.admin .add-item-button.fee-entity-add {
  font-size: 13px;
  font-style: italic;
  padding: 0;
}

.payment-methods-list {
  text-transform: capitalize;  
}

.table .table-row-subsection {
  background-color: $gray5;

  .add-new-fee-wrapper {
    padding-top: 10px;
  }

  table.fee-rules-table {
    border-collapse: collapse;
    width:100%;    

    tr {      
      border: none;

      td,
      th {
        padding: 5px 0;
        border: 1px solid $gray12;
        border-left: none;
        border-right: none;

        &.fee-rules-table-section-draggable {
          @include make-col-ready();
          @include make-col(1);
        }

        &.fee-rules-table-section-feename {
          @include make-col-ready();
          @include make-col(5);
        }

        &.fee-rules-table-section-description {
          @include make-col-ready();
          @include make-col(7);
        }

        &.fee-rules-table-section-amount {
          @include make-col-ready();
          @include make-col(3);
        }

        &.fee-rules-table-section-conditions {
          @include make-col-ready();
          @include make-col(5);
        }

        &.fee-rules-table-section-active {
          @include make-col-ready();
          @include make-col(1);
        }

        &.fee-rules-table-section-actionbuttons {
          @include make-col-ready();
          @include make-col(2);
        }
      }

      th {
        border-bottom: none !important;
        padding: 8px 0;
      }
    }
  }
}

.fee-group-modal {
  .feegroup-error-wrapper-title {
    font-weight: 700;
  }
  
  .row {
      margin-bottom: 15px;
  }

  .modal-footer {
      .inner {
          .btn {
              margin-left: 10px;
          }
      }
  }  
}

