.palette {
    cursor: pointer;
    border: 1px solid #f0f8ff;
    float: left;
    margin: 1px;
    width: 20px;
    height: 20px;
}

.coloring {
    border: 1px solid #ff00ff;
}

#examplePalette {
    display: none;
}

.clear {
    clear: left;
}