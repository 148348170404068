@import '../shared/helpers/bootstrap_variables';

.header-top, nav {
  @extend .container-fluid;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-top {
  height: 65px;
}

.header-brand, .header-actions {
  display: inline-flex;
  align-items: center;
}

.header-actions, .header-help-and-settings, .header-supersite, .header-quick-actions, .header-username {
  list-style: none;
  margin: 0;
  padding: 0;
}

.header-search, .header-user {
  background: $primary;
  display: flex;
  border: 2px solid $primary;
  border-radius: 5px;
  align-items: center;
}

.header-user .header-username {
  padding-top: 8px;
  padding-bottom: 8px;
}

.header-school-name {
  margin: 0 0 0 15px;
  color: $gray8;
}

.header-username {
  // Account for the 2px of border
  padding: 0 4px 0 10px;
  cursor: pointer;
}

.header-username > li > a {
  color: $white;

  // Notification counter
  &:not([data-count="0"]):before {
    content: attr(data-count);
    color: $white;
    margin-right: 5px;
    font-size: 9px;
    background: $red1;
    border-radius: 50%;
    border: 2px solid $red0;
    line-height: 16px;
    text-align: center;
    padding: 0 3px;
  }
}

nav {
  background: $gray8;
  height: 50px;
}

.module-link, .header-help-and-settings > li > a {
  color: $white;

  font-weight: 400;

  &:hover {
    cursor: pointer;
    color: $white;
    background: rgba(0, 0, 0, .25);
  }
}

.module-link {
  font-size: 16px;
  padding: 15px;

  &:first-child {
    margin-left: -15px;
  }

  @include media-breakpoint-down(xl) {
    padding: 16px 7px;
    font-size: 14px;

    &:first-child {
      margin-left: -7px;
    }
  }
}

.header-help-and-settings > li > a {
  font-size: 14px;
  padding: 14px 15px;

  &:last-child {
    margin-right: -7px;
  }

  &.active {
    font-weight: bold;
  }

  @include media-breakpoint-down(xl) {
    font-size: 12px;
    padding: 16px 10px;
  }
}

.header-help-and-settings > li {
  display: inline-flex;
}

.module-link-active {
  font-weight: 700;
}

.header-quick-actions {
  margin: 1px 15px 0 0;
}

.header-quick-actions > li > a {
  border: 2px solid $primary;
  padding: 7px 11px;
  border-radius: 5px;
  font-size: 18px;

  &:after {
    font-size: 18px;
    content: '\f067';
    border: 0;
    margin: 0;
    vertical-align: unset;
  }

  &:hover {
    color: $white;
    cursor: pointer;
    background: $primary;
  }
}

.header-quick-actions > li > a, .header-supersite > li > a {
  &:after {
    font-size: 18px;
    border: 0;
    margin: 0;
    vertical-align: unset;
  }
}

.header-quick-actions > li > a {
  &:after {
    content: '\f067';
  }
}

.header-supersite > li > a {
  background: $primary;
  color: $white;
  padding: 12px 11px;
  border-radius: 5px;
  margin: 0 0 0 15px;
  cursor: pointer;

  &:after {
    content: '\f0e8';
  }
}

.header-search-box {
  @extend .input-group;
}

.header-search-input {
  @extend .form-control;

  border: 0;
  border-radius: 3px;

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: $gray74;
  }
}

.header-search-icon {
  @extend .input-group-text;

  background: $white;
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: $body-color;
  border: 0;
}

.ac_results li {
  font-family: $font-family-base;
  padding-top: 5px;
  padding-bottom: 5px;
}
