#container .dashboard {
  h2 {
    color: $black;
    font-weight: 500;
    font-size: 21px;
  }

  .count-headers {
    margin: 25px 0 0 0;
    color: $black;
    font-weight: normal;
    font-size: 18px;
  }

  .filters {
    .terms {
      margin-bottom:15px;
    }
  }

}
